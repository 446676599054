import React, { Component } from "react";
import Globals from "../globals/globals";
import ordersImage from "./../assets/img/img-ordini-home.jpg";
import revenueImage from "./../assets/img/img-fatturato-home.png";
import accountStatementImage from "./../assets/img/img-estratto-conto-home.png";
import emailAutoImage from "./../assets/img/img-email-automatica.jpg";
import confEmailAutoImage from "./../assets/img/img-configurazione-email-automatica.jpg";
import { Link } from "react-router-dom";

export default class Home extends Component {
   constructor(props) {
      super(props);

      this.state = {
         language: props.language,
         accessToken: props.accessToken,
         isAdmin: false,
      };
   }

   componentDidMount() {
      document.title = Globals.titles.home[this.state.language];

      this.checkUserAdmin();
   }

   componentWillUnmount() {
      document.body.classList.remove("home-box");

      this.checkUserAdmin();
   }

   checkUserAdmin() {
      let accessToken = this.state.accessToken;

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=getUserData&access_token=" +
         accessToken;
      fetch(url, {
         method: "GET",
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               this.setState({
                  userData: {},
               });
            } else {
               // Stop loading state
               this.setState({
                  userData: responseJson.userData,
                  loading: true,
               });

               if (this.state.userData.type === "admin") {
                  this.setState({
                     isAdmin: true,
                  });
               }

               if (this.state.userData.type !== "admin") {
                  this.setState({
                     isAdmin: false,
                  });
               }
            }
         })
         .catch((error) => {
            this.setState({
               userData: {},
            });
         });
   }

   componentWillReceiveProps(props) {
      this.setState({
         language: props.language,
      });
      document.title = Globals.titles.home[props.language];
   }

   render() {

      return (
         <div className="home-box">
            <div className="home-links">
               <Link to="/orders">
                  <div className="section-contaier">
                     <div className="section-link">
                        <div className="image-wrapper">
                           <img src={ordersImage} alt="Orders" />
                        </div>
                        <div className="text-wrapper">
                           <div className="text">
                              {Globals.messages.myOrders[this.state.language]}
                           </div>
                        </div>
                     </div>
                  </div>
               </Link>
               <Link to="/accountstatement">
                  <div className="section-contaier">
                     <div className="section-link">
                        <div className="image-wrapper">
                           <img
                              src={accountStatementImage}
                              alt="Accountstatement"
                           />
                        </div>
                        <div className="text-wrapper">
                           <div className="text">
                              {
                                 Globals.messages.myAccountStatement[
                                    this.state.language
                                 ]
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </Link>
               <Link to="/revenue">
                  <div className="section-contaier">
                     <div className="section-link">
                        <div className="image-wrapper">
                           <img src={revenueImage} alt="Revenue" />
                        </div>
                        <div className="text-wrapper">
                           <div className="text">
                              {Globals.messages.myRevenue[this.state.language]}
                           </div>
                        </div>
                     </div>
                  </div>
               </Link>
               {this.state.isAdmin ? (
                  <Link to="/automaticemails">
                     <div className="section-contaier">
                        <div className="section-link">
                           <div className="image-wrapper">
                              <img src={emailAutoImage} alt="AutomaticEmails" />
                           </div>
                           <div className="text-wrapper">
                              <div className="text">
                                 {
                                    Globals.messages.automaticemail[
                                       this.state.language
                                    ]
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                  </Link>
               ) : (
                  ""
               )}
               {this.state.isAdmin ? (
                  <Link to="/config-emails">
                     <div className="section-contaier">
                        <div className="section-link">
                           <div className="image-wrapper">
                              <img
                                 src={confEmailAutoImage}
                                 alt="ConfigAutomaticEmail"
                              />
                           </div>
                           <div className="text-wrapper">
                              <div className="text">
                                 {
                                    Globals.messages.configemail[
                                       this.state.language
                                    ]
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                  </Link>
               ) : (
                  ""
               )}
            </div>
         </div>
      );
   }
}
