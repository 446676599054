import React, { Component } from "react";

import Globals from "./../globals/globals.js";

export default class Footer extends Component {
   constructor(props) {
      super(props);

      this.state = {
         language: props.language,
      };
   }

   componentWillReceiveProps(props) {
      this.setState({ language: props.language });
   }

   render() {
      var modifyCookie = this.props.modifyCookie;
      var activeCookie  = this.props.activeCookieBar;

      return (
         <footer>
            <div id="footer">
               <div className="company-text">
                  <span className="bigger">Bruno Elio srl</span>
                  <br />
                  Via Sommacampagna, 63/D - 37137 Verona - Italia
                  <br />
                  {Globals.footer.vatCode[this.state.language]} IT03366690232 -{" "}
                  {Globals.footer.socialCapital[this.state.language]}: €
                  532.000,00
                  <br />
                  {Globals.footer.phone[this.state.language]}: +39 045 862 17 46
                  - {Globals.footer.fax[this.state.language]}: +39 045 864 90 43
                  - {Globals.footer.email[this.state.language]}:
                  info@eliovero.it
               </div>
               <div className="legal-links">
                  <a
                     href={Globals.footer.legalNotesLink[this.state.language]}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     {Globals.footer.legalNotes[this.state.language]}
                  </a>
                  <a
                     href={Globals.footer.termsOfUseLink[this.state.language]}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     {Globals.footer.termsOfUse[this.state.language]}
                  </a>
                  <a
                     href={Globals.footer.creditsLink[this.state.language]}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     {Globals.footer.credits[this.state.language]}
                  </a>

                  {parseInt(activeCookie) === 1 && 

                  <button
                     style={{
                        width: "5%",
                        backgroundColor: "transparent",
                        textTransform: "uppercase",
                        fontSize: "16px",
                     }}
                     onClick={() => {
                        modifyCookie();
                     }}
                  >
                     Cookie
                  </button>
                  }
               </div>
            </div>
         </footer>
      );
   }
}
