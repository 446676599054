import React, { Component } from 'react';
import Globals from "../globals/globals";

export default class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            language: props.language,
        }
    }

    componentDidMount(){
        document.title = Globals.titles.notFound[this.state.language];
    }

    componentWillReceiveProps(props) {
        this.setState({language: props.language});
    }

    render() {
        return(
            <div className="not-found-box">
                <h2>{Globals.pages.notFound[this.state.language]}</h2>
            </div>
        );
    }

}