import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import "font-awesome/css/font-awesome.min.css";
import Globals from "../globals/globals";

export default function Cookiebar(props) {
  
  const [FlagAnalitici, setAnalitici] = useState(0);

  const [FlagProfilazione, setProfilazione] = useState(0);

  const [title, setTitle] = useState ();

  const [descrizione, setDescrizione] = useState ();

  const [fraseCookie, fraseCookieBar] = useState ();

  const [titleCookie, titleCookieBar] = useState ();

  const [linkInfo, linkInfoNoteLegali] = useState ();
  const [activeCookie, SetActiveCookie] = useState (0);

  // componentWillReceiveProps(props) {
  //   this.setState({
  //      language: props.language,
  //   });
  //   document.title = Globals.titles.home[props.language];
  // }
 
  useEffect(() => {
    getPreferencesCookies();
    checkCookie();
  }, [title, descrizione, fraseCookie, titleCookie, linkInfo, props]);

  const getPreferencesCookies = () => {
    var url =
    Globals.baseUrl +
    "?lang=it&a=getPreferencesCookie" 

    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === true) {
          console.log("errore");
        } else {
          selectedCookie(
            parseInt(responseJson.cookie[0].cookie_analitici_app),
            parseInt(responseJson.cookie[0].cookie_profilazione_app)
          );
          SetActiveCookie(parseInt(responseJson.cookie[0].cookiebar_app));

          if(props.language === "it"){
          setTitle(responseJson.cookie[0].text_info_cookie_app_it);
          setDescrizione(responseJson.cookie[0].desc_preferenze_app_it);
          fraseCookieBar(responseJson.cookie[0].frase_cookiebar_app_it);
          titleCookieBar(responseJson.cookie[0].title_preferenze_app_it);
          linkInfoNoteLegali(responseJson.cookie[0].link_info_cookie_app_it);
          }
          else if(props.language === "en"){
          setTitle(responseJson.cookie[0].text_info_cookie_app_en);
          setDescrizione(responseJson.cookie[0].desc_preferenze_app_en);
          fraseCookieBar(responseJson.cookie[0].frase_cookiebar_app_en);
          titleCookieBar(responseJson.cookie[0].title_preferenze_app_en);
          linkInfoNoteLegali(responseJson.cookie[0].link_info_cookie_app_en);
          }
          else{
            setTitle(responseJson.cookie[0].text_info_cookie_app_de);
            setDescrizione(responseJson.cookie[0].desc_preferenze_app_de);
            fraseCookieBar(responseJson.cookie[0].frase_cookiebar_app_de);
            titleCookieBar(responseJson.cookie[0].title_preferenze_app_de);
            linkInfoNoteLegali(responseJson.cookie[0].link_info_cookie_app_de);
          }
        }
      });
  };

  const sendCookiePreferences = (necessari, analitici, profilazione) => {
      var url =
      Globals.baseUrl +
      "?lang=it&a=saveCookiePreferences" 
  
    let formdata = new FormData();

    FlagAnalitici === 1
      ? formdata.append("analitici", analitici)
      : formdata.append("analitici", 0);
    FlagProfilazione === 1
      ? formdata.append("profilazione", profilazione)
      : formdata.append("profilazione", 0);
    formdata.append("necessari", necessari);

    fetch(url, {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === true) {
          console.log("errore");
        }
      });
  };

  const selectedCookie = (analitici, profilazione) => {
    if (profilazione === 1) {
      document.getElementById("cookie_profilazione").style.display = "block";
      setProfilazione(1);
    } else {
      document.getElementById("cookie_profilazione").style.display = "none";
    }
    if (analitici === 1) {
      document.getElementById("cookie_analitici").style.display = "block";
      setAnalitici(1);
    } else {
      document.getElementById("cookie_analitici").style.display = "none";
    }
  };

  function getCookieFunction(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    var user = getCookieFunction("cookie_consent");
    if (user !== "" || activeCookie === 0) {
      document.getElementById("container").style.display = "none";
    } else {
      if (user !== "" && user !== null || activeCookie === 1 ) {
        document.getElementById("container").style.display = "block";
      }
    }
  }
  
  function acceptAllCookie() {
    var date = new Date();
    date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
    document.cookie = "cookie_consent= tecnici" + expires;
    document.getElementById("container").style.display = "none";
    sendCookiePreferences(1, 1, 1);
  }
  function acceptNecessaryCookie() {
    var date = new Date();
    date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
    document.cookie = "cookie_consent= tecnici" + expires;
    document.getElementById("container").style.display = "none";
    sendCookiePreferences(1, 0, 0);
  }
  function preferenzeCookie() {
    document.getElementById("theModal").style.display = "block";
    document.getElementById("overlay").style.display = "block";
  }
  function closeModal() {
    document.getElementById("theModal").style.display = "none";
    document.getElementById("overlay").style.display = "none";
  }
  function closeCookie(){
    if (window.confirm('Chiudendo la barra dei cookie verranno accettati solo i cookie necessari. Confermare?') === true) {
      document.getElementById("container").style.display = "none";
      acceptNecessaryCookie();
  } else {
      document.getElementById("container").style.display = "block";
  }
  }

  function confirmPreferences() {
    if (
      document.querySelector("#analitici").checked &&
      document.querySelector("#profilazione").checked
    ) {
      var date = new Date();
      date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
      document.cookie =
        "cookie_consent= tecnici| analitici | profilazione" + expires;
      document.getElementById("container").style.display = "none";
      document.getElementById("theModal").style.display = "none";
      document.getElementById("overlay").style.display = "none";
      sendCookiePreferences(1, 1, 1);
    } else if (document.querySelector("#analitici").checked) {
      var date = new Date();
      date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
      document.cookie = "cookie_consent= tecnici | analitici" + expires;
      document.getElementById("container").style.display = "none";
      document.getElementById("theModal").style.display = "none";
      document.getElementById("overlay").style.display = "none";
      sendCookiePreferences(1, 1, 0);
    } else if (document.querySelector("#profilazione").checked) {
      var date = new Date();
      date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
      document.cookie = "cookie_consent= tecnici| profilazione" + expires;
      document.getElementById("container").style.display = "none";
      document.getElementById("theModal").style.display = "none";
      document.getElementById("overlay").style.display = "none";
      sendCookiePreferences(1, 0, 1);
    } else {
      acceptNecessaryCookie();
    }
  }

  return (
    <div>
      <div id="container">
        {/* overlay */}
        <div id="overlay" className="overlay"></div>
        {/* componente cookiebar */}
        <CookieConsent
          location="bottom"
          buttonStyle={{ display: "none" }}
          className="cookiebar"
          style={{
            background: "#eeeeee",
            color: "#000000",
            width: "100%",
          }}
          expires={125}
        >
          
          <div onClick={() => closeCookie()} className="closeModal pull-right"></div>
          <strong style={{fontSize:"20px"}}>{title}</strong>
          <br></br>
          
          {fraseCookie}
          <div className="wrapperButton">
            <button
              onClick={() => acceptNecessaryCookie()}
              className="btnNecessary"
            >
              <div>{Globals.cookieBar.btnnecessari[props.language]}</div>
            </button>

            <button onClick={() => acceptAllCookie()} className="btnAcceptAll">
              <div>{Globals.cookieBar.btnaccept[props.language]}</div>
            </button>
          </div>
          <div className="preferences">
            <button
              className="pull-right btnPreferenze"
              onClick={() => preferenzeCookie()}
            >
              {Globals.cookieBar.btnpreferenze[props.language]}
            </button>
            <a
              className="pull-right"
              style={{
                color: "rgb(0, 102, 204)",
                fontSize: "18px",
                marginRight: "10px",
              }}
              href={linkInfo}
              target="_blank"
            >
              {Globals.cookieBar.linknotelegali[props.language]}
            </a>
          </div>
        </CookieConsent>
        {/* finestra modale */}
        <div className="modal" id="theModal" style={{ display: "none" }}>
          <article className="modal-container">
            <header className="modal-container-header">
              <h1 className="modal-container-title">{titleCookie}</h1>
              <div onClick={() => closeModal()} className="closeModal"></div>
            </header>
            <section className="modal-container-body rtf">
              <h2>
                {descrizione}
              </h2>

              <div className="row">
                <div className="col-md-10">
                  <h4>
                  <div>{Globals.cookieBar.cookienecessari[props.language]}</div>
                  </h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <label className="switch pull-right">
                    {/* checked */}
                    <input type="checkbox" id="necessari" />
                    <span
                      htmlFor="necessari"
                      className="slider-disable round"
                    ></span>
                  </label>
                </div>
              </div>
              <div className="row" id="cookie_analitici">
                <div className="col-md-10">
                  <h4>{Globals.cookieBar.cookieanalitici[props.language]}</h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <label className="switch pull-right">
                    <input type="checkbox" id="analitici" />
                    <span htmlFor="analitici" className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row" id="cookie_profilazione">
                <div className="col-md-10">
                  <h4>{Globals.cookieBar.cookieprofilazione[props.language]}</h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <label className="switch pull-right">
                    <input type="checkbox" id="profilazione" />
                    <span
                      htmlFor="profilazione"
                      className="slider round"
                    ></span>
                  </label>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <p className="pull-right nobottommargin link-ext">
                  <i className="fa fa-fw fa-external-link"></i>{Globals.cookieBar.textlinkinfo[props.language]}{" "}
                  <a href={linkInfo} target="_blank"> 
                    <span className="link-policy">{Globals.cookieBar.linknotelegali[props.language]}</span>
                  </a>
                </p>
              </div>
            </section>
            <footer className="modal-container-footer">
              <button
                className="button is-primary"
                onClick={() => confirmPreferences()}
              >
                {Globals.cookieBar.btnconferma[props.language]}
              </button>
            </footer>
          </article>
        </div>
      </div>
    </div>
  );

}
