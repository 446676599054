import React, { Component } from "react";
import Globals from "./../globals/globals.js";
import Loading from "./../components/Loading.js";
import "font-awesome/css/font-awesome.min.css";

import { Redirect } from "react-router-dom";

export default class Login extends Component {
   constructor(props) {
      super(props);

      this.state = {
         email: "",
         showEmailError: false,
         emailErrorMessage: "",
         password: "",
         showPasswordError: false,
         passwordPasswordMessage: "",
         showotpError: false,
         otpErrorMessage: "",


         language: props.language,
         loading: false,
         isLogged: props.isLogged,
         otp: false,
         otp_code: "",
         idOtp: null,
         attempt: 3,
      };
   }

   componentDidMount() {
      document.title = Globals.titles.login[this.state.language];
   }

   componentWillUnmount() {
      document.body.classList.remove("login-box");
   }

   componentWillReceiveProps(props) {
      this.setState({
         language: props.language,
         isLogged: props.isLogged,
      });
      document.title = Globals.titles.login[props.language];
   }

   setInputErrorMessage(field, message) {
      switch (field) {
         case "email":
            this.setState({
               showEmailError: true,
               emailErrorMessage: message,
            });
            break;
         case "password":
            this.setState({
               showPasswordError: true,
               passwordErrorMessage: message,
            });
            break;
         case "otp_code":
            this.setState({
               showotpError: true,
               otpErrorMessage: message,
               attempt: this.state.attempt - 1,
            });
            break;
      }
   }
   
   
   checkOtp() {
        // Reset previous input error
        this.setState({
            showotpError: false,
            otpErrorMessage: "",
        });

        let otp_code = this.state.otp_code ;

        let fieldsAreOk = true;

        // Check otp (mandatory field)
        if ( otp_code === "" || otp_code === null || otp_code === undefined ) {
            this.setInputErrorMessage('otp_code', Globals.alerts.mandatoryotp[this.state.language]);
            fieldsAreOk = false;
        }

        // If otp or password are empty exit the function
        if ( fieldsAreOk === false ) {
            return;
        }

        // Set the loading state (it's an async action)
        this.setState({
            loading: true
        });

        // Try to login
        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=otp_confirm';

        let formdata = new FormData();

        formdata.append("otp", otp_code);
        formdata.append("email", this.state.email);

        fetch( url, {
            method: 'POST',
            body: formdata
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                this.setInputErrorMessage('otp_code', responseJson.message);
                this.setState({
                    loading: false,
                });
            } else {
                // Salvo nel DB l'access token ricevuto
                localStorage.setItem("accessToken", responseJson.access_token);

                // Stop loading state
                this.setState({
                    loading: false,
                });

                // Pass access token to App.js and save the user as logged
                this.props.saveAccessTokenAfterLogin(responseJson.access_token);
            }
        })
        .catch((error) => {
            this.setInputErrorMessage('otp_code', Globals.errors.serverCall[this.state.language]);

            // Stop loading state
            this.setState({
                loading: false,
            });
        });
    }

   refreshOtp() {
        // Reset previous input error
        this.setState({
            showotpError: false,
            otpErrorMessage: "",
        });


        if ( this.state.isLogged) {
            return(
                <Redirect to="/" />
            );
        }
        if(this.state.otp){
            return(
                <Redirect
                    to={{
                     pathname: "/codeverification",
                     state: { email: "prova" }
                }}
        />
            );
        }

        // Try to login
        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=refreshOtp';

        let formdata = new FormData();


        formdata.append("id", this.state.idOtp);
        formdata.append("email", this.state.email);

        fetch( url, {
            method: 'POST',
            body: formdata
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                this.setInputErrorMessage('otp_code', responseJson.message);
                this.setState({
                    loading: false,
                });
            } else {
                // Stop loading state
                this.setState({
                    loading: false,
                    attempt: 3,
                });

                alert(Globals.messages.insertNewCodeOtp[this.state.language])
            }
        })
        .catch((error) => {
            this.setInputErrorMessage('otp_code', Globals.errors.serverCall[this.state.language]);
            // Stop loading state
            this.setState({
                loading: false,
            });
        });
    }


   doLogin() {
      // Reset previous input error
      this.setState({
         showEmailError: false,
         emailErrorMessage: "",
         showPasswordError: false,
         passwordErrorMessage: "",
      });

      let email = this.state.email;
      let password = this.state.password;

      let fieldsAreOk = true;

      // Check email (mandatory field)
      if (email === "" || email === null || email === undefined) {
         this.setInputErrorMessage(
            "email",
            Globals.alerts.mandatoryEmail[this.state.language]
         );
         fieldsAreOk = false;
      }

      // Check password (mandatory field)
      if (password === "" || password === null || password === undefined) {
         this.setInputErrorMessage(
            "password",
            Globals.alerts.mandatoryPassword[this.state.language]
         );
         fieldsAreOk = false;
      }

      // If email or password are empty exit the function
      if (fieldsAreOk === false) {
         return;
      }

      // Set the loading state (it's an async action)
      this.setState({
         loading: true,
      });

      // Try to login
      let url = Globals.baseUrl + "?lang=" + this.state.language + "&a=login";

      let formdata = new FormData();

      formdata.append("user", email);
      formdata.append("pass", password);

      fetch(url, {
         method: "POST",
         body: formdata,
      })
         .then((response) => response.json())
         .then((responseJson) => {
            //CASISTICA ERRORE
            if (responseJson.error === true) {

               this.setInputErrorMessage("password", responseJson.message);
               this.setState({
                  loading: false,
               });
            }

            //CASISTICA OTP
            if (responseJson.redirect === true) {
               this.setState({
                  otp: true,
                  idOtp: responseJson.id,
                  loading: false,
               });

            } 
            //CASISTICA AUTH CORRETTA 
            if(responseJson.error === false){

               // Salvo nel DB l'access token ricevuto
               localStorage.setItem("accessToken", responseJson.access_token);

               // Stop loading state
               this.setState({
                  loading: false,
               });

               // Pass access token to App.js and save the user as logged
               this.props.saveAccessTokenAfterLogin(responseJson.access_token);
            }
         })
         .catch((error) => {
            this.setInputErrorMessage(
               "password",
               Globals.errors.serverCall[this.state.language]
            );

            // Stop loading state
            this.setState({
               loading: false,
            });
         });
   }

   render() {
      let loading;
      if (this.state.loading === true) {
         loading = <Loading />;
      }

      if (this.state.isLogged) {
         return <Redirect to="/" />;
      }

      if((this.state.attempt === 0)){
         window.location.reload();
      }

      let otpErrorField;
      if (this.state.showotpError === true) {
         otpErrorField = (
            <div className="input-error-wrapper">
               {this.state.otpErrorMessage}
            </div>
         );
      }

      let attemptField;
      if (this.state.showotpError === true) {
         attemptField = (
            <div className="input-error-wrapper">
               {Globals.messages.remainingAttempts[this.props.language] +
                  "" +
                  this.state.attempt}
            </div>
         );
      }

      if (this.state.otp) {
         return (
            <div className="login-box">
               <div className="welcome-message">
                  {Globals.messages.insertCodeOtp[this.state.language]}
               </div>
               <div className="input-wrapper">
                  <input
                     type="text"
                     name="otp"
                     placeholder={Globals.placeholders.otp[this.state.language]}
                     value={this.state.otp_code}
                     onChange={(e) => this.setState({ otp_code: e.target.value })}
                  />
               </div>
               {otpErrorField}
               {attemptField}
               <div className="input-wrapper">
                  <button type="button" onClick={this.checkOtp.bind(this)}>
                     <i className="fa fa-sign-in"></i>
                     <span>
                        {Globals.placeholders.verify[this.state.language]}
                     </span>
                  </button>
               </div>
               <div className="input-wrapper">
                  <button type="button" onClick={this.refreshOtp.bind(this)}>
                     <i className="fa fa-refresh"></i>
                     <span>
                        {Globals.placeholders.regenerate0tp[this.state.language]}
                     </span>
                  </button>
               </div>
               {loading}
            </div>
         );
      }

      let emailErrorField;
      if (this.state.showEmailError === true) {
         emailErrorField = (
            <div className="input-error-wrapper">
               {this.state.emailErrorMessage}
            </div>
         );
      }

      let passwordErrorField;
      if (this.state.showPasswordError === true) {
         passwordErrorField = (
            <div className="input-error-wrapper">
               {this.state.passwordErrorMessage}
            </div>
         );
      }

      return (
         <div className="login-box">
            <div className="welcome-message">
               {Globals.messages.welcome[this.state.language]}
            </div>
            <div className="input-wrapper">
               <input
                  type="text"
                  name="username"
                  placeholder={Globals.placeholders.email[this.state.language]}
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
               />
            </div>
            {emailErrorField}
            <div className="input-wrapper">
               <input
                  type="password"
                  name="password"
                  placeholder={
                     Globals.placeholders.password[this.state.language]
                  }
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
               />
            </div>
            {passwordErrorField}
            <div className="input-wrapper">
               <button type="button" onClick={this.doLogin.bind(this)}>
                  <i className="fa fa-sign-in"></i>
                  <span>{Globals.placeholders.login[this.state.language]}</span>
               </button>
            </div>
            {loading}
         </div>
      );
   }
}
