import React, {Component, lazy, Suspense} from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
 
import Globals from './globals/globals.js';

import './assets/css/main.css';

import Menu from './components/Menu.js';
import Footer from './components/Footer.js';
import Loading from './components/Loading.js';
import NotFound from './components/NotFound.js';

import Login from './pages/Login.js';
import Home from './pages/Home.js';
import Orders from './pages/Orders.js';
import AccountStatement from './pages/AccountStatement.js';
import Revenue from './pages/Revenue.js';
import AutomaticEmails from './pages/AutomaticEmails.js';
import ConfigAutomaticEmail from './pages/ConfigAutomaticEmail.js';
import MenuUser from './components/MenuUser.js';
// import CodeVerification from './pages/CodeVerification.js';
import Cookiebar from "./pages/Cookiebar";



const AccountStatementAdminPdf = lazy(() => import('./pages/AccountStatementAdminPdf'));
const AccountStatementPdf = lazy(() => import('./pages/AccountStatementPdf'));

export default class App extends Component {


    constructor(props) {
        super(props);
        // Get language if stored in localStorage
        let storedLang = localStorage.getItem("language");


        switch (storedLang) {
            case 'it':
            case 'en':
            case 'de':
                break;
        }

        if ( storedLang === "" || storedLang === null || storedLang === undefined ) {
            storedLang = "en";
        }

        this.state = {
            language: storedLang,
            accessToken: "",
            isLogged: undefined,
            isAdmin: false, 
            userData: {},
            redirect: false,
            activeCookieBar: null,

        }
    }

    
    
    componentDidMount(props) {
        this.getActiveCookiebar();

        // Get access token if stored
        let accessToken = localStorage.getItem('accessToken');

        if ( accessToken !== undefined && accessToken !== null && accessToken !== '' ) {
            // Try to contact the server to see if it's still viable
            let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=is_logged&access_token=' + accessToken;

            fetch(url)
            .then((response) => response.json())
            .then((responseJson) => {
                if ( responseJson.error === true ) {
                    // Error received, reset accessToken so you go back to login
                    localStorage.setItem( 'accessToken', "" );
                    this.setState({
                        accessToken: "",
                        isLogged: false,
                    });
                } else {
                    if (responseJson.is_logged === true) {
                        // User is logged
                        this.setState({
                            accessToken: accessToken,
                            isLogged: true,
                        });

                    } else {
                        // Not logged, reset accessToken and go back to login
                        localStorage.setItem( 'accessToken', "" );
                        this.setState({
                            accessToken: "",
                            isLogged: false,
                        });
                    }
                }
            })
            .catch((error) => {
                // Error received, reset accessToken so you go back to login
                localStorage.setItem( 'accessToken', "" );
                this.setState({
                    accessToken: "",
                    isLogged: false,
                });
            });

        } else {
            // Go to the login page because you're certainly not logged
            localStorage.setItem( 'accessToken', "" );
            this.setState({
                accessToken: "",
                isLogged: false,
            });
        }
    }
    getActiveCookiebar() {
		let url = Globals.baseUrl + '?lang=' + this.state.language + "&a=getPreferencesCookie";
		fetch(url, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					return "done";
				} else {
					this.setState({
                        activeCookieBar: responseJson.cookie[0].cookiebar_app
                    });
				}
			});
	}
    modifyCookie() {
        document.getElementById("container").style.display = "block";
      }


    checkUserAdmin(){

        let accessToken = this.state.accessToken;

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=getUserData&access_token=' + accessToken;

        fetch( url, {
            method: 'GET'
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                this.setState({
                    userData: {},
                });
            } else {
                // Stop loading state
                this.setState({
                    userData: responseJson.userData,
                    loading: true, 
                });
                
                if( this.state.userData.type === "admin"){
                    this.setState({
                        isAdmin: true,
                    });
                }
                
                if ( this.state.userData.type !== "admin" ) { 
                    this.setState({
                        isAdmin: false,
                    })
                }

            }
        })
        .catch((error) => {
            this.setState({
                userData: {},
            });
        });

    }

    saveAccessTokenAfterLogin( accessToken ) {
        localStorage.setItem("accessToken", accessToken);
        this.setState({
            accessToken: accessToken,
            isLogged: true,
        });


    }

    changeSysLanguage( language ) {
        localStorage.setItem("language", language);
        this.setState({
            language: language,
            redirect: true
        });


    }

    doLogout() {
        let accessToken = this.state.accessToken;

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=logout&access_token=' + accessToken;


        fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                console.log(responseJson.message);
            }
        })
        .catch((error) => {
            console.error( error );
        });
        
        localStorage.setItem("accessToken", "");
        this.setState({
            accessToken: "",
            isLogged: false,
        });

        }


    render() {
        let redirect;


        if ( this.state.isLogged === false  ) {
            redirect = <Redirect to={{ pathname: '/login' }} />;
        }
        

        let pages;
        if ( this.state.isLogged === undefined ) {
            pages = <Loading/>;
        } else {

                if(this.state.redirect && this.state.isLogged === true)
                {
                    
                    redirect = <Redirect to={{ pathname: '/' }} />;
                    
                    this.setState({
                        redirect: false 
                    });
               
                }
            
                    pages = <div>
                    <Switch>
                        <Route exact path="/" render={(props) => <Home language={this.state.language}  accessToken={this.state.accessToken} />} />
                        <Route path="/orders" render={(props) => <Orders language={this.state.language} accessToken={this.state.accessToken} />} />
                        <Route path="/accountstatement" render={(props) => <AccountStatement language={this.state.language} accessToken={this.state.accessToken} />} />
                        <Route path="/automaticemails" render={(props) => <AutomaticEmails language={this.state.language} accessToken={this.state.accessToken} />} />
                        <Route path="/config-emails" render={(props) => <ConfigAutomaticEmail language={this.state.language} accessToken={this.state.accessToken} />} />
                        <Route path="/statement/pdf" render={(props) => <AccountStatementPdf language={this.state.language} accessToken={this.state.accessToken} />} />
                        <Route path="/multistatement/pdf" render={(props) => <AccountStatementAdminPdf language={this.state.language} accessToken={this.state.accessToken} />} />
                        <Route path="/revenue" render={(props) => <Revenue language={this.state.language} accessToken={this.state.accessToken} />} />
                        <Route path="/login" render={(props) => <Login saveAccessTokenAfterLogin={this.saveAccessTokenAfterLogin.bind(this)} isLogged={this.state.isLogged} language={this.state.language} />} />
                        <Route render={() => <NotFound language={this.state.language} />} />
                    </Switch>
                </div>;                

        
        }

        if(this.state.isLogged === true ){
            return(
                <Router>
                    <Suspense fallback={<Loading/>}>
                    <div className="page-wrapper">
                        <Menu doLogout={this.doLogout.bind(this)} language={this.state.language} isLogged={this.state.isLogged} accessToken={this.state.accessToken} changeLanguage={this.changeSysLanguage.bind(this)}/>
                        <div className="site-content">
                            {pages}
                            {redirect}
                        </div>
                        {/* <Footer modifyCookie={this.modifyCookie.bind(this)} language={this.state.language} /> */}
                        <Footer
						modifyCookie={this.modifyCookie.bind(this)}
						language={this.state.language}
                        activeCookieBar={this.state.activeCookieBar}
					/>
                        
                    </div>
                    </Suspense>
                    <ToastContainer />
                    
                    <Cookiebar language={this.state.language} />
                </Router>
            );
        }else{
            return(
                <Router>
                    <Suspense fallback={<Loading/>}>
                    <div className="page-wrapper">
                        <MenuUser doLogout={this.doLogout.bind(this)} language={this.state.language} isLogged={this.state.isLogged} accessToken={this.state.accessToken} changeLanguage={this.changeSysLanguage.bind(this)}/>
                        <div className="site-content">
                            {pages}
                            {redirect}
                        </div>
                        <Footer
						modifyCookie={this.modifyCookie.bind(this)}
						language={this.state.language}
                        activeCookieBar={this.state.activeCookieBar}
					/>
                        
                    </div>
                    </Suspense>
                    <ToastContainer />
                    
                    <Cookiebar language={this.state.language} />
                </Router>
            );
        }
    }
}
