import React, { Component } from 'react';
import Globals from "../globals/globals";
import logo from './../assets/img/logo.png';
import { Link } from "react-router-dom";
import Loading from './../components/Loading.js';


import 'font-awesome/css/font-awesome.min.css';

class MenuUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            language: props.language,
            menuState: 0,
            isLogged: props.isLogged,
            accessToken: props.accessToken,
            isAdmin: false,
            loading:false, 
        }
    }

    toggleMenu() {
        this.setState({
            menuState: 1 - this.state.menuState
        });

    }

    componentDidMount(){
        
        let accessToken = this.props.accessToken;

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=getUserData&access_token=' + accessToken;

        fetch( url, {
            method: 'GET'
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                this.setState({
                    userData: {},
                });
            } else {
                // Stop loading state
                this.setState({
                    userData: responseJson.userData,
                    loading: true, 
                });
                
                if( this.state.userData.type === "admin"){
                    this.setState({
                        isAdmin: true,
                        loading:false,
                    });
                }
                
                if ( this.state.userData.type !== "admin" ) { 
                    this.setState({
                        isAdmin: false,
                        loading: false,  
                    })
                }

            }
        })
        .catch((error) => {
            this.setState({
                userData: {},
            });
        });


    }

    componentWillReceiveProps(props) {
        this.setState({
            language: props.language,
            isLogged: props.isLogged,
            accessToken: props.accessToken,
        });
    }

    handleClickWithMenuOpened( event ) {
        if ( event.target.closest( '.menu-sidebar' ) === null ) {
            this.toggleMenu();
        }
    }

    render() {
        if(this.state.loading === true){
        
            return(
                <Loading/>
            );
        }
        var changeLanguage = this.props.changeLanguage;
        var doLogout = this.props.doLogout;
        let menuvoices;
        if ( this.state.isLogged === true) {
            menuvoices = <div className="pages-links">
                <Link to="/" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-home"></i><span>{Globals.menuVoices.home[this.state.language]}</span>
                    </div>
                </Link>
                <Link to="/orders" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-file-text-o"></i><span>{Globals.menuVoices.orders[this.state.language]}</span>
                    </div>
                </Link>
                <Link to="/accountstatement" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-money"></i><span>{Globals.menuVoices.accountstatement[this.state.language]}</span>
                    </div>
                </Link>
                <Link to="/revenue" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-line-chart"></i><span>{Globals.menuVoices.revenue[this.state.language]}</span>
                    </div>
                </Link>
                {(this.state.isAdmin === true)?<Link to="/automaticemails" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-reply-all"></i><span>{Globals.menuVoices.automaticemail[this.state.language]}</span>
                    </div>
                </Link>:""}
                {(this.state.isAdmin === true)?<Link to="/config-emails" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-cog"></i><span>{Globals.menuVoices.configemail[this.state.language]}</span>
                    </div>
                </Link>:""}
                <div className="page" onClick={() => {if ( window.confirm( Globals.confirms.logout[this.state.language] ) ) {this.toggleMenu();doLogout();}}}>
                    <i className="fa fa-sign-out"></i><span>{Globals.menuVoices.logout[this.state.language]}</span>
                </div>
            </div>;
        }

        return(
            <header>
                <div className="menu-bar">
                    <div className="top-bar">
                        <div className="icon open" onClick={this.toggleMenu.bind( this )}></div>
                        <div className="logo-wrapper">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                        <div className="language-selection-wrapper"></div>
                    </div>
                    <div className={this.state.menuState === 1 ? "menu-body opened" : "menu-body closed"} onClick={(event) => this.handleClickWithMenuOpened(event)}>
                        <div className="menu-sidebar">
                            <div className="sidebar-content">
                                <div className="sidebar-top-bar">
                                    <div className="icon close" onClick={this.toggleMenu.bind( this, 1 )}></div>
                                    <div className="logo-wrapper">
                                        <img src={logo} alt="logo" />
                                    </div>
                                </div>
                                <div className="language-selection-wrapper">
                                    <div className={this.state.language === 'it' ? "language current" : "language"} onClick={() => changeLanguage('it')}>IT</div>
                                    <div className={this.state.language === 'en' ? "language current" : "language"} onClick={() => changeLanguage('en')}>EN</div>
                                    <div className={this.state.language === 'de' ? "language current" : "language"} onClick={() => changeLanguage('de')}>DE</div>
                                </div>
                                {menuvoices}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

}

export default MenuUser;