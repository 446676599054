import React, { Component } from "react";
import Globals from "../globals/globals";
import Loading from "./../components/Loading.js";
import { Redirect } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import Select from "react-select";
import ReactTable from "react-table";
import moment from "moment";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import "react-table/react-table.css";

import it from "date-fns/locale/it";
import en from "date-fns/locale/en-GB";
import de from "date-fns/locale/de";

registerLocale("it", it);
registerLocale("en", en);
registerLocale("de", de);

let controller = new AbortController();

export default class Orders extends Component {
   constructor(props) {
      super(props);

      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 5);

      this.state = {
         language: props.language,
         accessToken: props.accessToken,
         clientSelected: {
            value: "",
            label: Globals.labels.allClients[props.language],
         },
         startDate: startDate,
         endDate: new Date(),
         userData: null,
         ordersData: null,
         ordersMessage: "",

         //selectedOption: [],

         modalShippingDocumentsData: null,
         modalShippingInfoData: null,
         loading: true,

         isMounted: false,
      };

      this.handleClientChange = this.handleClientChange.bind(this);
      this.handleStartDateChange = this.handleStartDateChange.bind(this);
      this.handleOnDrop = this.handleOnDrop.bind(this);
      this.handleEndDateChange = this.handleEndDateChange.bind(this);
   }

   handleClientChange(element) {
      this.setState({
         clientSelected: element,
      });
   }

   handleStartDateChange(date) {
      this.setState({
         startDate: date,
      });
   }

   handleEndDateChange(date) {
      this.setState({
         endDate: date,
      });
   }

   componentWillUnmount() {
      document.body.classList.remove("order-page");
   }

   componentDidMount() {
      document.body.classList.add("order-page");

      document.title = Globals.titles.orders[this.state.language];

      // Fetch api for the user data
      let accessToken = this.state.accessToken;

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=getUserData&access_token=" +
         accessToken;

      fetch(url, {
         method: "GET",
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               this.setState({
                  userData: {},
               });
            } else {
               // Stop loading state
               this.setState({
                  userData: responseJson.userData,
               });

               if (this.state.userData.type === "admin") {
                  this.setState({
                     isMounted: true,
                  });
               }

               if (this.state.userData.type !== "admin") {
                  this.getOrders();
               }
            }
         })
         .catch((error) => {
            this.setState({
               userData: {},
            });
         });
   }

   componentWillReceiveProps(props) {
      this.setState({
         language: props.language,
         accessToken: props.accessToken,
      });
      document.title = Globals.titles.orders[props.language];
   }

   formatDate(date) {
      var d = new Date(date),
         month = "" + (d.getMonth() + 1),
         day = "" + d.getDate(),
         year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
   }

   openModalShippingDocuments(shippingDocumentsData) {
      this.setState({
         modalShippingDocumentsData: shippingDocumentsData,
      });
   }

   openModalShippingInfo(shippingInfoData) {
      this.setState({
         modalShippingInfoData: shippingInfoData,
      });
   }

   getOrders() {
      // Check dates

      if (this.state.userData.type === "admin") {
         this.setState({
            isMounted: false,
         });
      }

      if (this.state.endDate.getTime() < this.state.startDate.getTime()) {
         this.setState({
            ordersData: [],
            ordersMessage:
               Globals.errors.nonSequentialDates[this.state.language],
         });
         return;
      }

      // Reset state
      this.setState({
         ordersData: null,
         ordersMessage: "",
      });

      // Fetch api for user's orders
      let accessToken = this.state.accessToken;

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=getOrders&access_token=" +
         accessToken;

      let formdata = new FormData();

      formdata.append("startDate", this.formatDate(this.state.startDate));
      formdata.append("endDate", this.formatDate(this.state.endDate));

      //let listclient = this.state.selectedOption;
      if (
         this.state.userData.type === "admin" ||
         this.state.userData.type === "partner"
      ) {
         formdata.append("clientCode", this.state.clientSelected.value);
      }

      fetch(url, {
         method: "POST",
         body: formdata,
         signal: controller.signal,
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               // Error during the call - handle message
               this.setState({
                  ordersData: [],
                  ordersMessage: responseJson.message,
               });
            } else {
               // Call is ok, store ordersData in state
               this.setState({
                  ordersData: responseJson.orders,
                  ordersMessage: "",
               });
            }
         })
         .catch((error) => {
            // Communication error - handle message
            if (error.name === "AbortError") {
               // handle abort()
               this.setState({
                  ordersData: [],
                  ordersMessage:
                     Globals.reactTableText.stopSignal[this.state.language],
               });
            } else {
               this.setState({
                  ordersData: [],
                  ordersMessage: Globals.errors.serverCall[this.state.language],
               });
            }
         });
   }

   handleOnDrop(files) {
      this.setState({
         loading: true,
      });

      // Fetch api
      let accessToken = this.state.accessToken;

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=UploadFileCertificate&access_token=" +
         accessToken;

      const formdata = new FormData();
      formdata.append(
         "id",
         this.state.modalShippingDocumentsData[0].nordovestref
      );

      files.forEach((file) => {
         formdata.append("uploadedFiles[]", file, file.name);
      });

      fetch(url, {
         method: "POST",
         body: formdata,
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               // Error during the call - handle message
               toast(responseJson.message, {
                  autoClose: 8000,
                  className: "std-black-toast",
                  bodyClassName: "std-black-toast-body",
                  progressClassName: "std-black-toast-progress-bar",
               });

               this.setState({
                  loading: false,
               });
            } else {
               // Call is ok
               toast("Operazione completata", {
                  autoClose: 4000,
                  className: "std-black-toast",
                  bodyClassName: "std-black-toast-body",
                  progressClassName: "std-black-toast-progress-bar",
               });

               this.setState({
                  loading: false,
               });

               this.openModalShippingDocuments(null);
               this.getOrders();
            }
         })
         .catch((error) => {
            // Communication error - handle message
            toast(Globals.errors.serverCall[this.state.language], {
               autoClose: 8000,
               className: "std-black-toast",
               bodyClassName: "std-black-toast-body",
               progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
               loading: false,
            });
         });
   }

   deleteCertificate(name) {
      if (window.confirm("Confermi l'eliminazione?")) {
         this.setState({
            loading: true,
         });

         // Fetch api for user's orders
         let accessToken = this.state.accessToken;

         let url =
            Globals.baseUrl +
            "?lang=" +
            this.state.language +
            "&a=DeleteFileCertificate&access_token=" +
            accessToken;

         let formdata = new FormData();
         let documentName = name;

         formdata.append(
            "nordovestref",
            this.state.modalShippingDocumentsData[0].nordovestref
         );
         formdata.append(
            "title",
            documentName
         );

         fetch(url, {
            method: "POST",
            body: formdata,
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.error === true) {
                  // Error during the call - handle message
                  toast(responseJson.message, {
                     autoClose: 8000,
                     className: "std-black-toast",
                     bodyClassName: "std-black-toast-body",
                     progressClassName: "std-black-toast-progress-bar",
                  });

                  this.setState({
                     loading: false,
                  });
               } else {
                  // Call is ok
                  toast("Operazione completata", {
                     autoClose: 4000,
                     className: "std-black-toast",
                     bodyClassName: "std-black-toast-body",
                     progressClassName: "std-black-toast-progress-bar",
                  });

                  this.setState({
                     loading: false,
                  });

                  this.openModalShippingDocuments(null);
                  this.getOrders();
               }
            })
            .catch((error) => {
               // Communication error - handle message

               toast(Globals.errors.serverCall[this.state.language], {
                  autoClose: 8000,
                  className: "std-black-toast",
                  bodyClassName: "std-black-toast-body",
                  progressClassName: "std-black-toast-progress-bar",
               });

               this.setState({
                  loading: false,
               });
            });
      }
   }

   stopLoading() {
      controller.abort();
      controller = new AbortController();
   }

   render() {
      if (this.state.userData === null) {
         return <Loading />;
      }

      if (this.state.userData.name === undefined) {
         return <Redirect to={{ pathname: "/" }} />;
      }

      let clientsFilter = "";
      if (
         (this.state.userData.type === "admin" &&
            this.state.userData.clients !== undefined) ||
         this.state.userData.type === "partner"
      ) {
         //let selectOptions = [];

         let selectOptions = [
            {
               value: "",
               label: Globals.labels.allClients[this.state.language],
            },
         ];

         for (let c = 0; c < this.state.userData.clients.length; c++) {
            selectOptions.push({
               value: this.state.userData.clients[c].codconto,
               label: this.state.userData.clients[c].label,
            });
         }

         // let clientsSelect = <MultiSelect
         // options={selectOptions}
         // selected={this.state.selectedOption}
         // onSelectedChanged={(selected) => this.setState({selectedOption: selected})}
         // overrideStrings={{
         //     selectSomeItems: Globals.placeholders.selectsomeitems[this.state.language],
         //     allItemsAreSelected: Globals.placeholders.allitemsareselected[this.state.language],
         //     selectAll: Globals.placeholders.selectall[this.state.language],
         //     search: Globals.placeholders.search[this.state.language],
         // }}/>;

         let selectedOption = this.state.clientSelected;

         let clientsSelect = (
            <Select
               value={selectedOption}
               onChange={this.handleClientChange}
               options={selectOptions}
               isSearchable={true}
               isClearable={true}
            />
         );

         clientsFilter = (
            <div className="filter-wrapper-multiselect">
               <div className="label">
                  {Globals.labels.client[this.state.language]}
               </div>
               <div className="filter multiselect-wrapper">{clientsSelect}</div>
            </div>
         );
      }

      let ordersData = this.state.ordersData;
      let ordersMessage = this.state.ordersMessage;
      let ordersRender = "";

      if (this.state.isMounted === true) {
         ordersRender = (
            <div className="message-wrapper">
               {Globals.messages.selectFilter[this.state.language]}
            </div>
         );
      } else {
         if (ordersData === null) {
            ordersRender = (
               <div className="loading-wrapper">
                  <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                  <div className="loading-wrapper">
                     {" "}
                     <button
                        type="button"
                        className="stopbutton"
                        onClick={() => this.stopLoading()}
                     >
                        {Globals.labels.stop[this.state.language]}
                     </button>{" "}
                  </div>
               </div>
            );
         } else {
            if (ordersMessage !== "") {
               ordersRender = (
                  <div className="message-wrapper">{ordersMessage}</div>
               );
            } else {
               // Prepare data for the table;
               const columns = [
                  {
                     Header: Globals.labels.date[this.state.language],
                     accessor: "date",
                     Cell: (c) => (
                        <span>
                           {moment(c.original.date).format("DD/MM/YYYY")}
                        </span>
                     ),
                     width: 150,
                  },
                  {
                     Header: Globals.labels.company[this.state.language],
                     accessor: "company",
                     width: 450,
                  },
                  {
                     Header: Globals.labels.documents[this.state.language],
                     accessor: "documents",
                  },
               ];
               const data = [];
               for (let c = 0; c < ordersData.length; c++) {
                  let documents = (
                     <div className="documents-list">
                        {ordersData[c].documents.map(function (item, key) {
                           if (
                              item.name !== "Fattura pro forma" &&
                              item.name !== "Proforma invoice" &&
                              item.name !== "Pro Forma Rechnung"
                           ) {
                              return (
                                 <span className="element" key={key}>
                                    <a
                                       href={item.url}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                    >
                                       <i className="fa fa-file-pdf-o fa-lg"></i>
                                       &nbsp;{item.name}
                                    </a>
                                    <br />
                                 </span>
                              );
                           }
                        })}
                        {ordersData[c].shippingDocumentsFiles.length >= 1 && (
                           <span className="element">
                              <a
                                 onClick={() =>
                                    this.openModalShippingDocuments(
                                       ordersData[c].shippingDocumentsFiles
                                    )
                                 }
                              >
                                 <i className="fa fa-file-o fa-lg"></i>&nbsp;
                                 {
                                    Globals.labels.shippingDocuments[
                                       this.state.language
                                    ]
                                 }
                              </a>
                           </span>
                        )}
                        {ordersData[c].shippingInfo.length !== 0 && (
                           <span className="element">
                              <a
                                 onClick={() =>
                                    this.openModalShippingInfo(
                                       ordersData[c].shippingInfo
                                    )
                                 }
                              >
                                 <i className="fa fa-info-circle fa-lg"></i>
                                 &nbsp;
                                 {
                                    Globals.labels.shippingInfo[
                                       this.state.language
                                    ]
                                 }
                              </a>
                           </span>
                        )}
                     </div>
                  );

                  // Push data
                  data.push({
                     date: ordersData[c].datadoc,
                     company: ordersData[c].dscconto1,
                     documents: documents,
                  });
               }
               // Render Table
               ordersRender = (
                  <ReactTable
                     data={data}
                     columns={columns}
                     pageSizeOptions={[15, 25, 50, 100]}
                     defaultPageSize={15}                  
                     previousText={
                        Globals.reactTableText.previous[this.state.language]
                     }
                     nextText={Globals.reactTableText.next[this.state.language]}
                     loadingText={
                        Globals.reactTableText.loading[this.state.language]
                     }
                     noDataText={
                        Globals.reactTableText.noData[this.state.language]
                     }
                     pageText={Globals.reactTableText.page[this.state.language]}
                     ofText={Globals.reactTableText.of[this.state.language]}
                     rowsText={Globals.reactTableText.rows[this.state.language]}
                     pageJumpText={
                        Globals.reactTableText.pageJumpText[this.state.language]
                     }
                     rowsSelectorText={
                        Globals.reactTableText.rowsSelectorText[
                           this.state.language
                        ]
                     }
                  />
               );
            }
         }
      }
      // Shipping Documents Modal
      let shippingDocumentsModalBody;
      if (this.state.modalShippingDocumentsData !== null) {
         const shippingDocumentsColumns = [
            {
               Header: Globals.labels.documents[this.state.language],
               accessor: "documentName",
            },
            {
               Header: () => (
                  <div style={{ textAlign: "center" }}>
                     {Globals.labels.download[this.state.language]}
                  </div>
               ),
               accessor: "downloadIcon",
               width: 100,
               Cell: (row) => (
                  <div style={{ textAlign: "center" }}>{row.value}</div>
               ),
            },
            {
               Header: Globals.labels.deleteCertificate[this.state.language],
               width: 100,
               accessor: "deleteIcon",
               Cell: (row) => (
                  <div style={{ textAlign: "center" }}>{row.value}</div>
               ),
               show: this.state.userData.type === "admin" ? true : false,
            },
         ];
         const shippingDocumentsTableData = [];
         for (
            let c = 0;
            c < this.state.modalShippingDocumentsData.length;
            c++
         ) {
            let currentDocument = this.state.modalShippingDocumentsData[c];
            // Push data
            if (currentDocument.name === "Fattura") {
               currentDocument.name =
                  Globals.labels.shippingrevenue[this.state.language];
            }

            shippingDocumentsTableData.push({
               documentName: currentDocument.name,
               downloadIcon: (
                  <span className="tableIconElement">
                     <a
                        href={currentDocument.url}
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        <i className="fa fa-file-pdf-o"></i>
                     </a>
                  </span>
               ),
               deleteIcon: (this.state.modalShippingDocumentsData[c].type === "External_document")?(
                  <i className="std-hoverable-cursor-pointer fa fa-trash fa-2x" onClick={() => {this.deleteCertificate(this.state.modalShippingDocumentsData[c].name)}}></i>
               ): <span></span>
            });
         }

         shippingDocumentsModalBody = (
            <div className="modal">
               <div className="background"></div>
               <div className="body small">
                  <div className="titleBar">
                     {Globals.labels.shippingDocuments[this.state.language]}
                     <div
                        className="close-button"
                        onClick={() => this.openModalShippingDocuments(null)}
                     >
                        <i className="fa fa-times"></i>
                     </div>
                  </div>
                  <div className="content">
                     <ReactTable
                        data={shippingDocumentsTableData}
                        columns={shippingDocumentsColumns}
                        showPagination={false}
                        pageSize={shippingDocumentsTableData.length}
                        previousText={
                           Globals.reactTableText.previous[this.state.language]
                        }
                        nextText={
                           Globals.reactTableText.next[this.state.language]
                        }
                        loadingText={
                           Globals.reactTableText.loading[this.state.language]
                        }
                        noDataText={
                           Globals.reactTableText.noData[this.state.language]
                        }
                        pageText={
                           Globals.reactTableText.page[this.state.language]
                        }
                        ofText={Globals.reactTableText.of[this.state.language]}
                        rowsText={
                           Globals.reactTableText.rows[this.state.language]
                        }
                        pageJumpText={
                           Globals.reactTableText.pageJumpText[
                              this.state.language
                           ]
                        }
                        rowsSelectorText={
                           Globals.reactTableText.rowsSelectorText[
                              this.state.language
                           ]
                        }
                     />

                     {this.state.userData.type === "admin" ? (
                           <Dropzone
                              onDrop={this.handleOnDrop}
                              accept="application/pdf"
                           >
                              {({
                                 getRootProps,
                                 getInputProps,
                                 isDragActive,
                              }) => (
                                 <section>
                                    <div
                                       {...getRootProps()}
                                       className={
                                          "drag-and-drop-wrapper " +
                                          (isDragActive ? "active" : "")
                                       }
                                    >
                                       <input {...getInputProps()} />
                                       <p>
                                          Trascina qui i tuoi file (es.
                                          certificato d'origine) o fai click{" "}
                                          <span>qui</span> per selezionarli
                                          <span className="small">
                                             (File accettati: .pdf)
                                          </span>
                                       </p>
                                    </div>
                                 </section>
                              )}
                           </Dropzone>
                     ) : (
                        <span></span>
                     )}
                  </div>
               </div>
            </div>
         );
      }

      // Shipping Info modal
      let shippingInfoModalBody;
      if (this.state.modalShippingInfoData !== null) {
         const shippingInfoContainerTrackingColumns = [
            {
               Header: Globals.labels.event[this.state.language],
               accessor: "event",
            },
            {
               Header: Globals.labels.eventDate[this.state.language],
               accessor: "eventDate",
               Cell: (row) => (
                  <span>{moment(row.value).format("DD/MM/YYYY")}</span>
               ),
            },
            {
               Header: Globals.labels.eventLocation[this.state.language],
               accessor: "eventLocation",
            },
            {
               Header: Globals.labels.carrier[this.state.language],
               accessor: "carrier",
            },
            {
               Header: Globals.labels.vehicleOrVessel[this.state.language],
               accessor: "vehicleOrVessel",
            },
         ];
         let shippingInfo = this.state.modalShippingInfoData;
         shippingInfoModalBody = (
            <div className="modal">
               <div className="background"></div>
               <div className="body small">
                  <div className="titleBar">
                     {Globals.labels.shippingInfo[this.state.language]}
                     <div
                        className="close-button"
                        onClick={() => this.openModalShippingInfo(null)}
                     >
                        <i className="fa fa-times"></i>
                     </div>
                  </div>
                  <div className="content">
                     <div className="shippingGeneralInfoContainer">
                        <div className="data">
                           <span className="label">
                              {Globals.labels.consignee[this.state.language]}:
                           </span>{" "}
                           {shippingInfo.consignee}
                        </div>
                        <div className="data">
                           <span className="label">
                              {Globals.labels.country[this.state.language]}:
                           </span>{" "}
                           {shippingInfo.country}
                        </div>
                        <div className="data">
                           <span className="label">
                              {
                                 Globals.labels.customerReference[
                                    this.state.language
                                 ]
                              }
                              :
                           </span>{" "}
                           {shippingInfo.customerreference}
                        </div>
                        <div className="data">
                           <span className="label">
                              {Globals.labels.goods[this.state.language]}:
                           </span>{" "}
                           {shippingInfo.goods}
                        </div>
                        <div className="data">
                           <span className="label">
                              {Globals.labels.etd[this.state.language]}:
                           </span>{" "}
                           {moment(shippingInfo.estimatedtimedeparture).format(
                              "DD/MM/YYYY"
                           )}
                        </div>
                        <div className="data">
                           <span className="label">
                              {Globals.labels.eta[this.state.language]}:
                           </span>{" "}
                           {moment(shippingInfo.estimatedtimearrival).format(
                              "DD/MM/YYYY"
                           )}
                        </div>
                     </div>
                     {shippingInfo.containers.map(function (item, key) {
                        const shippingInfoContainerTrackingTableData = [];
                        if (item.movements) {
                           for (let c = 0; c < item.movements.length; c++) {
                              let currentMovement = item.movements[c];
                              // Push data
                              shippingInfoContainerTrackingTableData.push({
                                 event: currentMovement.eventlabel,
                                 eventDate: currentMovement.eventdate,
                                 eventLocation: currentMovement.eventlocation,
                                 carrier: currentMovement.carrier,
                                 vehicleOrVessel:
                                    currentMovement.vehicleorvessel,
                              });
                           }
                           return (
                              <div key={key} className="containerInfoContainer">
                                 <div className="title">
                                    Container {item.containernumber}
                                 </div>
                                 <div className="table-container">
                                    <ReactTable
                                       data={
                                          shippingInfoContainerTrackingTableData
                                       }
                                       columns={
                                          shippingInfoContainerTrackingColumns
                                       }
                                       showPagination={false}
                                       pageSize={
                                          shippingInfoContainerTrackingTableData.length
                                       }
                                    />
                                 </div>
                              </div>
                           );
                        }
                     })}
                  </div>
               </div>
            </div>
         );
      }

      return (
         <div className="orders-page">
            <div className="filters">
               <div className="filters-wrapper">
                  {clientsFilter}
                  <div className="filter-wrapper">
                     <div className="label">
                        {Globals.labels.startDate[this.state.language]}
                     </div>
                     <div className="filter">
                        <DatePicker
                           locale={this.state.language}
                           selected={this.state.startDate}
                           dateFormat="dd/MM/yyyy"
                           onChange={this.handleStartDateChange}
                        />
                     </div>
                  </div>
                  <div className="filter-wrapper">
                     <div className="label">
                        {Globals.labels.endDate[this.state.language]}
                     </div>
                     <div className="filter">
                        <DatePicker
                           locale={this.state.language}
                           selected={this.state.endDate}
                           dateFormat="dd/MM/yyyy"
                           onChange={this.handleEndDateChange}
                        />
                     </div>
                  </div>
                  <div className="filter-wrapper">
                     <div className="label">&nbsp;</div>
                     <div className="filter">
                        <button type="button" onClick={() => this.getOrders()}>
                           {Globals.labels.apply[this.state.language]}
                        </button>
                     </div>
                  </div>
               </div>
            </div>
            {ordersRender}
            {shippingDocumentsModalBody}
            {shippingInfoModalBody}
         </div>
      );
   }
}
