import React, { Component } from "react";
import Globals from "../globals/globals";
import Loading from "./../components/Loading.js";
import { Redirect, Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import Select from "react-select";

import ReactTable from "react-table";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import "react-datepicker/dist/react-datepicker.css";

import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import "react-table/react-table.css";

import it from "date-fns/locale/it";
import en from "date-fns/locale/en-GB";
import de from "date-fns/locale/de";

registerLocale("it", it);
registerLocale("en", en);
registerLocale("de", de);

let controller = new AbortController();

export default class AccountStatement extends Component {
   constructor(props) {
      super(props);

      this.state = {
         language: props.language,
         accessToken: props.accessToken,
         clientSelected: {
            value: "",
            label: Globals.labels.allClients[props.language],
         },
         startDate: null,
         endDate: null,
         userData: null,

         opendeadlinesData: null,
         opendeadlinesMessage: "",
         totaldeadlinesData: null,
         lastcheck: null,


         loading: true,
         isMounted: false,
      };
      this.handleClientChange = this.handleClientChange.bind(this);
      this.handleStartDateChange = this.handleStartDateChange.bind(this);
      this.handleEndDateChange = this.handleEndDateChange.bind(this);
   }

   getTrProps = (state, rowInfo, instance) => {
      if (rowInfo) {
         return {
            style: {
               background: moment(rowInfo.row.datascadenza).isBefore(moment())
                  ? "#fa8734"
                  : "white",
               color: "white",
            },
         };
      }
      return {};
   };

   handleClientChange(element) {
      this.setState({
         clientSelected: element,
      });
   }

   handleStartDateChange(date) {
      this.setState({
         startDate: date,
      });
   }

   handleEndDateChange(date) {
      this.setState({
         endDate: date,
      });
   }

   formatDate(date) {
      var d = new Date(date),
         month = "" + (d.getMonth() + 1),
         day = "" + d.getDate(),
         year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      if (year === 1970) {
         return null;
      }

      return [year, month, day].join("-");
   }

   componentWillUnmount() {
      document.body.classList.remove("accountstatement-page");
   }

   componentDidMount() {
      window.scrollTo(0, 1000);
      document.body.classList.add("accountstatement-page");

      document.title = Globals.titles.accountstatement[this.state.language];

      // Fetch api for the user data
      let accessToken = this.state.accessToken;

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=getUserData&access_token=" +
         accessToken;

      this.setState({
         opendeadlinesData: [],
      });

      fetch(url, {
         method: "GET",
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               this.setState({
                  userData: {},
               });
            } else {
               // Stop loading state
               this.setState({
                  userData: responseJson.userData,
               });

               if (this.state.userData.type === "admin") {
                  this.setState({
                     isMounted: true,
                  });
               }

               if (this.state.userData.type !== "admin") {
                  this.getStatement();
               }
            }
         })
         .catch((error) => {
            this.setState({
               userData: {},
            });
         });
   }

   componentWillReceiveProps(props) {
      this.setState({
         language: props.language,
         accessToken: props.accessToken,
      });
      document.title = Globals.titles.accountstatement[props.language];
   }

   getStatement() {
      let formdata = new FormData();

      if (this.state.userData.type === "admin") {
         this.setState({
            isMounted: false,
         });
      }

      if (
         this.formatDate(this.state.endDate) <
         this.formatDate(this.state.startDate)
      ) {
         this.setState({
            opendeadlinesData: [],
            totaldeadlinesData: [],
            opendeadlinesMessage:
               Globals.errors.nonSequentialDates[this.state.language],
         });
         return;
      }

      if (
         (!this.formatDate(this.state.endDate) &&
            this.formatDate(this.state.startDate)) ||
         (this.formatDate(this.state.endDate) &&
            !this.formatDate(this.state.startDate))
      ) {
         this.setState({
            opendeadlinesData: [],
            totaldeadlinesData: [],
            opendeadlinesMessage:
               Globals.errors.dateMissing[this.state.language],
         });
         return;
      }

      this.setState({
         opendeadlinesData: null,
         opendeadlinesMessage: "",
      });

      let accessToken = this.state.accessToken;

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=getAccountstatement&access_token=" +
         accessToken;

      if (this.state.startDate !== null && this.state.endDate !== null) {
         formdata.append("startDate", this.formatDate(this.state.startDate));
         formdata.append("endDate", this.formatDate(this.state.endDate));
      }

      //let listclient = this.state.selectedOption;

      if (
         this.state.userData.type === "admin" ||
         this.state.userData.type === "partner"
      ) {
         //formdata.append( "clientCode",  JSON.stringify(listclient));
         formdata.append("clientCode", this.state.clientSelected.value);
      }

      fetch(url, {
         method: "POST",
         body: formdata,
         signal: controller.signal,
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               // Error during the call - handle message
               this.setState({
                  opendeadlinesMessage: responseJson.message,
                  opendeadlinesData: [],
                  totaldeadlinesData: [],
               });
            } else {
               // Call is ok, store AccountstaementData in state
               this.setState({
                  opendeadlinesMessage: "",
                  opendeadlinesData: responseJson.opendeadlines,
                  totaldeadlinesData: responseJson.totdeadlines,
                  lastcheck: responseJson.last_check,

               });
            }
         })
         .catch((error) => {
            // Communication error - handle message
            if (error.name === "AbortError") {
               // handle abort()
               this.setState({
                  opendeadlinesData: [],
                  totaldeadlinesData: [],
                  opendeadlinesMessage:
                     Globals.reactTableText.stopSignal[this.state.language],
               });
            } else {
               this.setState({
                  opendeadlinesData: [],
                  totaldeadlinesData: [],
                  opendeadlinesMessage:
                     Globals.errors.serverCall[this.state.language],
               });
            }
         });
   }

   stopLoading() {
      controller.abort();
      controller = new AbortController();
   }

   render() {
      if (this.state.userData === null) {
         return <Loading />;
      }

      if (this.state.userData.name === undefined) {
         return <Redirect to={{ pathname: "/" }} />;
      }

      let clientsFilter = "";
      if (
         (this.state.userData.type === "admin" &&
            this.state.userData.clients !== undefined) ||
         this.state.userData.type === "partner"
      ) {
         //let selectOptions = [];
         let selectOptions = [
            {
               value: "",
               label: Globals.labels.allClients[this.state.language],
            },
         ];

         for (let c = 0; c < this.state.userData.clients.length; c++) {
            selectOptions.push({
               value: this.state.userData.clients[c].codconto,
               label: this.state.userData.clients[c].label,
            });
         }

         let selectedOption = this.state.clientSelected;

         let clientsSelect = (
            <Select
               value={selectedOption}
               onChange={this.handleClientChange}
               options={selectOptions}
               isSearchable={true}
               isClearable={true}
            />
         );

         clientsFilter = (
            <div className="filter-wrapper-multiselect">
               <div className="label">
                  {Globals.labels.client[this.state.language]}
               </div>
               <div className="filter multiselect-wrapper">{clientsSelect}</div>
            </div>
         );
      }

      let opendeadlinesMessage = this.state.opendeadlinesMessage;

      let opendeadlinesData = this.state.opendeadlinesData;
      let opendeadlinesRender = "";
      let lastcheckRender = "";

      let renderpdfbutton = "";

      if (this.state.isMounted === true) {
         opendeadlinesRender = (
            <div className="message-wrapper">
               {Globals.messages.selectFilter[this.state.language]}
            </div>
         );
      } else {
         if (opendeadlinesData === null) {
            opendeadlinesRender = (
               <div className="loading-wrapper">
                  <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                  <div className="loading-wrapper">
                     {" "}
                     <button
                        type="button"
                        className="stopbutton"
                        onClick={() => this.stopLoading()}
                     >
                        {Globals.labels.stop[this.state.language]}
                     </button>{" "}
                  </div>
               </div>
            );

            renderpdfbutton = <span></span>;
         } else {
            if (opendeadlinesMessage !== "") {
               opendeadlinesRender = (
                  <div className="message-wrapper">{opendeadlinesMessage}</div>
               );

               renderpdfbutton = <span></span>;
            } else {
               const columnsopen = [
                  {
                     Header: Globals.labels.codcli[this.state.language],
                     accessor: "codcli",
                     show:
                        this.state.userData.type === "admin" ||
                        this.state.userData.type === "partner"
                           ? true
                           : false,
                  },
                  {
                     Header: Globals.labels.company[this.state.language],
                     accessor: "disconto",
                     show:
                        this.state.userData.type === "admin" ||
                        this.state.userData.type === "partner"
                           ? true
                           : false,
                  },
                  {
                     Header: Globals.labels.esercizio[this.state.language],
                     accessor: "annoft",
                     width: 150,
                     filterable: true,
                     filterMethod: (filter, row) => {
                        return row[filter.id]
                           .toLowerCase()
                           .includes(filter.value.toLowerCase());
                     },
                  },
                  {
                     Header: Globals.labels.mese[this.state.language],
                     accessor: "meseft",
                     width: 100,
                     filterable: true,
                     filterMethod: (filter, row) => {
                        return row[filter.id]
                           .toLowerCase()
                           .includes(filter.value.toLowerCase());
                     },
                  },
                  {
                     Header: Globals.labels.numfattura[this.state.language],
                     accessor: "numrif",
                     width: 150,
                  },
                  {
                     Header: Globals.labels.datafattura[this.state.language],
                     Cell: (c) => (
                        <span>{moment(c.value).format("DD/MM/YYYY")}</span>
                     ),
                     accessor: "datafattura",
                     width: 250,
                  },
                  {
                     Header: Globals.labels.datascadenza[this.state.language],
                     Cell: (c) => (
                        <span>{moment(c.value).format("DD/MM/YYYY")}</span>
                     ),
                     accessor: "datascadenza",
                     width: 250,
                  },
                  {
                     Header:
                        Globals.labels.importototalescadenza[
                           this.state.language
                        ],
                     accessor: "importoscad",
                     Cell: (c) => (
                        <CurrencyFormat
                           value={c.value}
                           displayType={"text"}
                           thousandSeparator={","}
                           decimalScale={2}
                           prefix={" € "}
                           renderText={(value) => <div>{value}</div>}
                        />
                     ),
                     width: 250,
                  },
                  {
                     Header: Globals.labels.documents[this.state.language],
                     accessor: "documents",
                  },
               ];

               const dataopen = [];
               for (let d = 0; d < opendeadlinesData.length; d++) {
                  let documents = (
                     <div className="documents-list">
                        {opendeadlinesData[d].documents.map(function (
                           item,
                           key
                        ) {
                           return (
                              <span className="element" key={key}>
                                 <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                 >
                                    <i className="fa fa-file-pdf-o fa-lg"></i>
                                    &nbsp;
                                    {item.name}
                                 </a>
                                 <br />
                              </span>
                           );
                        })}
                     </div>
                  );

                  dataopen.push({
                     codcli: opendeadlinesData[d].codcli,
                     disconto: opendeadlinesData[d].dscconto1,
                     annoft: opendeadlinesData[d].annoft,
                     meseft: opendeadlinesData[d].meseft,
                     numrif: opendeadlinesData[d].numrif,
                     datafattura: opendeadlinesData[d].datafattura,
                     datascadenza: opendeadlinesData[d].datascadenza,
                     importoscad: opendeadlinesData[d].importoscad,
                     documents: documents,
                  });
               }

               // Render Table
               opendeadlinesRender = (
                  <ReactTable
                     data={dataopen}
                     columns={columnsopen}
                     pageSizeOptions={[5, 15, 25, 50, 100]}
                     defaultPageSize={25}
                     page={this.state.page}
                     onPageChange={(page) => this.setState({ page })}
                     previousText={
                        Globals.reactTableText.previous[this.state.language]
                     }
                     nextText={Globals.reactTableText.next[this.state.language]}
                     loadingText={
                        Globals.reactTableText.loading[this.state.language]
                     }
                     noDataText={
                        Globals.reactTableText.noData[this.state.language]
                     }
                     pageText={Globals.reactTableText.page[this.state.language]}
                     ofText={Globals.reactTableText.of[this.state.language]}
                     rowsText={Globals.reactTableText.rows[this.state.language]}
                     pageJumpText={
                        Globals.reactTableText.pageJumpText[this.state.language]
                     }
                     rowsSelectorText={
                        Globals.reactTableText.rowsSelectorText[
                           this.state.language
                        ]
                     }
                     getTrProps={this.getTrProps}
                  />
               );

               //Se admin verrà disposto riga per riga, se user verrà intabellato
               if (this.state.userData.type === "admin") {
                  if (this.state.totaldeadlinesData === null) {
                     renderpdfbutton = <span></span>;
                  } else {
                     if (this.state.totaldeadlinesData.length === 1) {
                        renderpdfbutton = (
                           <Link
                              to={{
                                 pathname: "/statement/pdf",
                                 state: {
                                    statement: this.state.opendeadlinesData,
                                    total: this.state.totaldeadlinesData,
                                 },
                              }}
                           >
                              <button type="button" className="buttonFilter">
                                 {Globals.labels.exportpdf[this.state.language]}
                              </button>
                           </Link>
                        );
                     } else {
                        renderpdfbutton = "";
                     }
                  }
               }

               if (this.state.userData.type === "partner") {
                  if (this.state.totaldeadlinesData === null) {
                     renderpdfbutton = <span></span>;
                  } else {
                     if (this.state.totaldeadlinesData.length === 1) {
                        renderpdfbutton = (
                           <Link
                              to={{
                                 pathname: "/statement/pdf",
                                 state: {
                                    statement: this.state.opendeadlinesData,
                                    total: this.state.totaldeadlinesData,
                                 },
                              }}
                           >
                              <button type="button" className="buttonFilter">
                                 {Globals.labels.exportpdf[this.state.language]}
                              </button>
                           </Link>
                        );
                     } else {
                        renderpdfbutton = (
                           <Link
                              to={{
                                 pathname: "/multistatement/pdf",
                                 state: {
                                    statement: this.state.opendeadlinesData,
                                    total: this.state.totaldeadlinesData,
                                 },
                              }}
                           >
                              <button type="button" className="buttonFilter">
                                 {" "}
                                 {Globals.labels.exportpdf[this.state.language]}
                              </button>
                           </Link>
                        );
                     }
                  }
               }
               if (this.state.userData.type === "user") {
                  renderpdfbutton = (
                     <Link
                        to={{
                           pathname: "/statement/pdf",
                           state: {
                              statement: this.state.opendeadlinesData,
                              total: this.state.totaldeadlinesData,
                           },
                        }}
                     >
                        <button type="button" className="buttonFilter">
                           {Globals.labels.exportpdf[this.state.language]}
                        </button>
                     </Link>
                  );
               }

               if (this.state.lastcheck !== null ) {

                  let t = this.state.lastcheck;
                  lastcheckRender = <p> {Globals.messages.lastdateModified[this.state.language]} :  {moment(t[0].last_time).format("DD/MM/YYYY, HH:mm")} </p>;
               }
            }
         }
      }

      return (
         <div className="accountstatement-page">
            <div className="filters">
            {lastcheckRender}
               <div className="filters-wrapper">
                  {clientsFilter}
                  <div className="filter-wrapper">
                     <div className="label">
                        {
                           Globals.labels.startDateStatements[
                              this.state.language
                           ]
                        }
                     </div>
                     <div className="filter">
                        <DatePicker
                           locale={this.state.language}
                           selected={this.state.startDate}
                           dateFormat="dd/MM/yyyy"
                           onChange={this.handleStartDateChange}
                           placeholderText={
                              Globals.placeholders.startdate[
                                 this.state.language
                              ]
                           }
                        />
                     </div>
                  </div>
                  <div className="filter-wrapper">
                     <div className="label">
                        {Globals.labels.endDateStatements[this.state.language]}
                     </div>
                     <div className="filter">
                        <DatePicker
                           locale={this.state.language}
                           selected={this.state.endDate}
                           dateFormat="dd/MM/yyyy"
                           onChange={this.handleEndDateChange}
                           placeholderText={
                              Globals.placeholders.endate[this.state.language]
                           }
                        />
                     </div>
                  </div>
                  <div className="filter-wrapper">
                     <div className="label">&nbsp;</div>
                     <div className="filter">
                        <button
                           type="button"
                           className="buttonFilter"
                           onClick={() => this.getStatement()}
                        >
                           {Globals.labels.apply[this.state.language]}
                        </button>
                     </div>
                  </div>
                  <div className="filter-wrapper">
                     <div className="label">&nbsp;</div>
                     <div className="filter">{renderpdfbutton}</div>
                  </div>
               </div>
            </div>
            {opendeadlinesRender}
         </div>
      );
   }
}
