import React from "react";

const Globals = {
  // baseUrl: "https://eliovero.readynet.it/webservices/1.0/",

  baseUrl: "https://www.eliovero.it/webservices/1.0/",

  menuVoices: {
    home: {
      it: "Home",
      en: "Home",
      de: "Home",
    },
    orders: {
      it: "Archivio Documenti",
      en: "Orders catologue",
      de: "Bestellungen katalog",
    },
    accountstatement: {
      it: "Estratto Conto",
      en: "Statement of Account",
      de: "Kontoauszug",
    },
    revenue: {
      it: "Fatturato",
      en: "Sales volume",
      de: "Verkaufsvolumen",
    },
    automaticemail: {
      it: "Email Automatiche",
      en: "Automatic Emails",
      de: "Automatische E-Mails",
    },
    configemail: {
      it: "Configurazione Email Auto.",
      en: "Automatic Email Conf.",
      de: "Automatische E-Mail-Konf.",
    },
    logout: {
      it: "Logout",
      en: "Logout",
      de: "Logout",
    },
  },

  titles: {
    home: {
      it: "APT Eliovero - Home",
      en: "APT Eliovero - Home",
      de: "APT Eliovero - Home",
    },
    orders: {
      it: "APT Eliovero - Archivio Documenti",
      en: "APT Eliovero - Orders Catalogue",
      de: "APT Eliovero - Bestellungen katalog",
    },
    accountstatement: {
      it: "APT Eliovero - Estratto Conto",
      en: "APT Eliovero - Statement of Account",
      de: "APT Eliovero - Kontoauszug",
    },
    automaticemail: {
      it: "APT Eliovero - Email Automatiche",
      en: "APT Eliovero - Automatic Emails",
      de: "APT Eliovero - Automatische E-Mails",
    },
    configemail: {
      it: "APT Eliovero - Conf.email auto",
      en: "APT Eliovero - Conf.email auto",
      de: "APT Eliovero - Konf.email auto",
    },
    revenue: {
      it: "APT Eliovero - Fatturato ",
      en: "APT Eliovero - Sales volume",
      de: "APT Eliovero - Verkaufsvolumen",
    },
    login: {
      it: "APT Eliovero - Login",
      en: "APT Eliovero - Login",
      de: "APT Eliovero - Login",
    },
    notFound: {
      it: "APT Eliovero - Contenuto non trovato",
      en: "APT Eliovero - Not found",
      de: "APT Eliovero - Not found",
    },
  },

  confirms: {
    logout: {
      it: "Confermi il logout dall'applicazione?",
      en: "Confirm logout from the application?",
      de: "Abmeldung von der Anwendung bestätigen?",
    },
  },

  errors: {
    serverCall: {
      it: "Si è verificato un errore durante la comunicazione con il server",
      en: "An error occurred while communicating with the server",
      de: "Bei der Kommunikation mit dem Server ist ein Fehler aufgetreten",
    },
    nonSequentialDates: {
      it: "La data di inizio è successiva alla data di fine",
      en: "The start date is later than the end date",
      de: "Das Startdatum liegt nach dem Enddatum",
    },
    dateMissing: {
      it: "Inserire la il filtro data mancante",
      en: "Insert missing date filter",
      de: "Filter für fehlendes Datum einfügen",
    },
  },

  alerts: {
    mandatoryEmail: {
      it: "Email è un campo obbligatorio",
      en: "Email is a mandatory field",
      de: "Email ist ein Pflichtfeld",
    },
    mandatoryPassword: {
      it: "Password è un campo obbligatorio",
      en: "Password is a mandatory field",
      de: "Passwort ist ein Pflichtfeld",
    },
    mandatoryotp: {
      it: "OTP è un campo obbligatorio",
      en: "OTP is a mandatory field",
      de: "OTP ist ein Pflichtfeld",
    },
  },

  pages: {
    notFound: {
      it: (
        <div>
          Ops,
          <br />
          il contenuto che stai cercando non esiste
          <br />
          oppure non è mai esistito
        </div>
      ),
      en: (
        <div>
          Ops,
          <br />
          the content you're searching for doesn't exists
          <br />
          or it never existed
        </div>
      ),
      de: (
        <div>
          Ops,
          <br />
          Der gesuchte Inhalt ist nicht vorhanden
          <br />
          oder es hat nie existiert
        </div>
      ),
    },
  },

  messages: {
    welcome: {
      it: "Benvenuto nella tua area personale",
      en: "Welcome in your personal area",
      de: "Willkommen in Ihrem persönlichen Bereich",
    },
    insertCodeOtp: {
      it: "Controlla la casella di posta, ti è stato inviato un codice OTP da inserire",
      en: "Check your inbox, an OTP code has been sent to you to enter",
      de: "Überprüfen Sie Ihren Posteingang, ein OTP-Code wurde Ihnen zur Eingabe zugesandt",
    },
    insertNewCodeOtp: {
      it: "Controlla la casella di posta, ti è stato inviato un nuovo codice OTP da inserire",
      en: "Check your inbox, an new OTP code has been sent to you to enter",
      de: "Überprüfen Sie Ihren Posteingang, ein neuer OTP-Code wurde Ihnen zur Eingabe zugesandt",
    },
    myOrders: {
      it: "Archivio Documenti",
      en: "Orders catologue",
      de: "Bestellungen katalog",
    },
    myAccountStatement: {
      it: "Estratto Conto",
      en: "Statement of Account",
      de: "Kontoauszug",
    },
    configemail: {
      it: "Configurazione Email Automatiche",
      en: "Automatic Email Configuration",
      de: "Automatische E-Mail-Konfiguration",
    },
    automaticemail: {
      it: "Email Automatiche",
      en: "Automatic Emails",
      de: "Automatische E-Mails",
    },
    myRevenue: {
      it: "Fatturato",
      en: "Sales volume",
      de: "Verkaufsvolumen",
    },
    totdeadlines: {
      it: "La somma del totale delle scadenze aperte ammonta a ",
      en: "The sum of the total of the open deadlines amounts to",
      de: "Die Summe der offenen Fristen beträgt ",
    },
    selectFilter: {
      it: "Selezionare il cliente, poi selezionare un filtro data e cliccare APPLICA.",
      en: "Select the customer, then select a date filter and click APPLY. ",
      de: "Wählen Sie den Kunden aus, wählen Sie dann einen Datumsfilter aus und klicken Sie auf ANWENDEN.  ",
    },
    message: {
      it: "CARICAMENTO ESPORTAZIONE ..",
      en: "EXPORT LOADING ..",
      de: "EXPORT LADEN ..",
    },
    exportationMessage: {
      it: "ESPORTAZIONE PDF IN CORSO... ",
      en: "PDF EXPORT IN PROGRESS ...",
      de: "PDF EXPORT IN FORTSCHRITT ...",
    },
    lastdateModified: {
      it: "Dati aggiornati al  ",
      en: "Data updated to ",
      de: "Daten aktualisiert auf",
    },
    remainingAttempts: {
      it: "Tentativi rimanenti :  ",
      en: "remaining attempts : ",
      de: "verbleibende Versuche : ",
    },
  },

  footer: {
    vatCode: {
      it: "P.IVA",
      en: "VAT CODE",
      de: "MwSt.-Nummer",
    },
    socialCapital: {
      it: "Capitale sociale",
      en: "Social capital",
      de: "Soziales Kapital",
    },
    phone: {
      it: "Telefono",
      en: "Phone",
      de: "Telefon",
    },
    fax: {
      it: "Fax",
      en: "Fax",
      de: "Fax",
    },
    email: {
      it: "Email",
      en: "Email",
      de: "Email",
    },
    legalNotes: {
      it: "Note legali",
      en: "Privacy policy",
      de: "Datenschutzerklärung",
    },
    legalNotesLink: {
      it: "https://www.eliovero.it/it/note_legali",
      en: "https://www.eliovero.it/en/privacy_policy",
      de: "https://www.eliovero.it/de/datenschutzerklarung",
    },
    termsOfUse: {
      it: "Condizioni d'utilizzo",
      en: "Terms of use",
      de: "Nutzungsbedingungen",
    },
    termsOfUseLink: {
      it: "https://www.eliovero.it/it/condizioni_utilizzo",
      en: "https://www.eliovero.it/en/terms_of_use",
      de: "https://www.eliovero.it/de/nutzungsbedingungen",
    },
    credits: {
      it: "Credits",
      en: "Credits",
      de: "Kredite",
    },
    creditsLink: {
      it: "https://www.eliovero.it/it/credits",
      en: "https://www.eliovero.it/en/credits",
      de: "https://www.eliovero.it/de/kredite",
    },
  },

  placeholders: {
    email: {
      it: "Email",
      en: "Email",
      de: "Email",
    },
    password: {
      it: "Password",
      en: "Password",
      de: "Passwort",
    },
    login: {
      it: "Login",
      en: "Login",
      de: "Login",
    },
    verify: {
      it: "Verifica",
      en: "Verify",
      de: "Verifizieren",
    },
    regenerate0tp: {
      it: "Rigenera Otp",
      en: "Regenerate Otp",
      de: "OTP-Code-Regeneration",
    },
    selectsomeitems: {
      it: "Seleziona uno o più clienti",
      en: "Select one or more clients",
      de: "Wählen Sie einen oder mehrere Kunden aus",
    },
    allitemsareselected: {
      it: "Tutti gli elementi sono selezionati",
      en: "Select one or more clients  ",
      de: "Wählen Sie einen oder mehrere Kunden aus ",
    },
    selectall: {
      it: "Seleziona tutti",
      en: "Select all",
      de: "Wählen Sie Alle",
    },
    search: {
      it: "Cerca",
      en: "Search for",
      de: "Suchen nach",
    },
    startdate: {
      it: "Clicca per selezionare ",
      en: "Click to select",
      de: "Klicke um auszuwählen",
    },
    endate: {
      it: "Clicca per selezionare ",
      en: "Click to select",
      de: "Klicke um auszuwählen",
    },
    otp: {
      it: "codice OTP",
      en: "code OTP",
      de: "OTP-code",
    },
  },

  reactTableText: {
    previous: {
      it: "Precedente",
      en: "Previous",
      de: "Bisherige",
    },
    next: {
      it: "Successivo",
      en: "Next",
      de: "Nächster",
    },
    loading: {
      it: "Caricamento...",
      en: "Loading...",
      de: "Wird geladen...",
    },
    noData: {
      it: "Nessun dato trovato",
      en: "No data found",
      de: "Keine Daten gefunden",
    },
    stopSignal: {
      it: "Caricamento annullato",
      en: "Loading was canceled",
      de: "Laden wurde abgebrochen ",
    },
    page: {
      it: "Pagina",
      en: "Page",
      de: "Seite",
    },
    of: {
      it: "di",
      en: "of",
      de: "von",
    },
    rows: {
      it: "righe",
      en: "rows",
      de: "reihen",
    },
    pageJumpText: {
      it: "salta alla pagina",
      en: "jump to page",
      de: "zur seite springen",
    },
    rowsSelectorText: {
      it: "righe per pagina",
      en: "rows per page",
      de: "zeilen pro seite",
    },
  },

  labels: {
    startDate: {
      it: "Data inizio",
      en: "Start date",
      de: "Startdatum",
    },
    endDate: {
      it: "Data fine",
      en: "End data",
      de: "Enddaten",
    },
    startDateStatements: {
      it: "Data Fattura inizio",
      en: "Invoice start date",
      de: "Rechnungsstartdatum",
    },
    endDateStatements: {
      it: "Data Fattura fine",
      en: "Invoice date end",
      de: "Rechnungsdatum endet",
    },
    client: {
      it: "Cliente",
      en: "Client",
      de: "Kunde",
    },
    email: {
      it: "Email",
      en: "Email",
      de: "Email",
    },
    emailauto: {
      it: "Email Automatica",
      en: "Automatic email",
      de: "automatische E-Mail",
    },
    allClients: {
      it: "Tutti i clienti",
      en: "All clients",
      de: "Alle Kunden",
    },
    selectClients: {
      it: "Seleziona cliente",
      en: "Select customer",
      de: "Kunden auswählen",
    },
    exportpdf: {
      it: "Esporta in Pdf ",
      en: "Export pdf",
      de: "Drucken im PDF",
    },
    apply: {
      it: "Applica",
      en: "Apply",
      de: "Anwenden",
    },
    applyFilter: {
      it: "Applica Filtro",
      en: "Apply Filter",
      de: "Filter anwenden",
    },
    stop: {
      it: "Annulla",
      en: "Stop",
      de: "Laden beenden",
    },
    date: {
      it: "Data",
      en: "Date",
      de: "Datum",
    },
    company: {
      it: "Azienda",
      en: "Company",
      de: "Unternehmen",
    },
    documentType: {
      it: "Tipo di documento",
      en: "Document type",
      de: "Art des Dokuments",
    },
    downloadDDT: {
      it: "DDT",
      en: "DDT",
      de: "DDT",
    },
    downloadAS: {
      it: "AS",
      en: "AS",
      de: "AS",
    },
    downloadTR: {
      it: "TR",
      en: "TR",
      de: "TR",
    },
    download: {
      it: "Scarica",
      en: "Download",
      de: "Herunterladen",
    },
    documents: {
      it: "Documenti",
      en: "Documents",
      de: "Unterlagen",
    },
    shippingDocuments: {
      it: "Documenti di viaggio",
      en: "Shipping documents",
      de: "Reisedokumente",
    },
    shippingInfo: {
      it: "Informazioni spedizione",
      en: "Shipping informations",
      de: "Versandinformationen",
    },
    consignee: {
      it: "Destinatario",
      en: "Consignee",
      de: "Empfänger",
    },
    country: {
      it: "Nazione",
      en: "Country",
      de: "Land",
    },
    customerReference: {
      it: "Riferimento cliente",
      en: "Customer reference",
      de: "Kundenreferenz",
    },
    goods: {
      it: "Merce",
      en: "Goods",
      de: "Waren",
    },
    etd: {
      it: "Data di partenza prevista",
      en: "Expected departure date",
      de: "Voraussichtliches Abreisedatum",
    },
    eta: {
      it: "Data di arrivo prevista",
      en: "Expected arrival date",
      de: "Voraussichtliches Ankunftsdatum",
    },
    event: {
      it: "Evento",
      en: "Event",
      de: "Veranstaltung",
    },
    eventDate: {
      it: "Data dell'evento",
      en: "Event date",
      de: "Veranstaltungsdatum",
    },
    eventLocation: {
      it: "Luogo dell'evento",
      en: "Event location",
      de: "Veranstaltungsort",
    },
    carrier: {
      it: "Vettore",
      en: "Carrier",
      de: "Träger",
    },
    vehicleOrVessel: {
      it: "Veicolo o Nave",
      en: "vehicle or vessel",
      de: "Fahrzeug/Schiffskonzepter",
    },
    codcli: {
      it: "Codice cliente ",
      en: "client code",
      de: "Client-Code",
    },
    esercizio: {
      it: "Esercizio",
      en: "Exercise",
      de: "Übung",
    },
    mese: {
      it: "Mese",
      en: "Month",
      de: "Monat",
    },
    fatturatoeuro: {
      it: "Fatturato",
      en: "Revenue",
      de: "Einnahmen",
    },
    fatturatoeuroef: {
      it: "Fatturato definitivo",
      en: "Final turnover ",
      de: "Endumsatz ",
    },
    fatturatoeurotf: {
      it: "Fatturato non definitivo",
      en: "Turnover not final ",
      de: "Umsatz nicht endgültig ",
    },
    datascadenza: {
      it: "Data scadenza fattura ",
      en: "Invoice due date ",
      de: "Rechnungsfälligkeitsdatum ",
    },
    numfattura: {
      it: "N° Fattura",
      en: "invoice number",
      de: "Rechnungsnummer ",
    },
    blocca: {
      it: "Blocca",
      en: "block",
      de: "block ",
    },
    datafattura: {
      it: "Data fattura",
      en: "Invoice date",
      de: "Rechnungsdatum ",
    },
    importototalescadenza: {
      it: "importo totale scadenza",
      en: "total amount due ",
      de: "Gesamtbetrag fällig ",
    },
    totale: {
      it: "Totale",
      en: "Total",
      de: "Gesamt",
    },
    da: {
      it: "da",
      en: "from ",
      de: "von",
    },
    a: {
      it: "a",
      en: "to",
      de: "bis",
    },
    linkscadenze: {
      it: "Link ad estratto conto ",
      en: "Link to account statement ",
      de: "Link zum Kontoauszug",
    },
    log: {
      it: "Storico email",
      en: "Email history ",
      de: "E-Mail-Verlauf",
    },
    Datainvio: {
      it: "Data-ora invio",
      en: "Date-time of sending",
      de: "Datum-Uhrzeit des Sendens",
    },
    Dataultimoinvio: {
      it: "Ultimo invio",
      en: "Last sent",
      de: "Zuletzt gesendet",
    },
    successo: {
      it: "successo",
      en: "success",
      de: "Erfolg",
    },
    invio: {
      it: "Invio",
      en: "Send",
      de: "senden",
    },
    percentualefido: {
      it: "% di fido occupato ",
      en: "% of credit occupied ",
      de: "% des Kredits belegt",
    },
    fatturatoattivo: {
      it: "Fatturato attivo ",
      en: "Turnover ",
      de: "Umsatz",
    },
    fidoeuro: {
      it: "Fido",
      en: "credit ",
      de: "Kreditrate",
    },
    shippingrevenue: {
      it: "Fattura",
      en: "Invoice",
      de: "Rechnung",
    },
    deleteCertificate: {
      it: "Elimina",
      en: "Delete",
      de: "löschen",
    },
  },
  pdfLabels: {
    Title: {
      it: "Estratto conto cliente ",
      en: "Statement of accounts ",
      de: "Kontoauszug ",
    },
    ExportationDate: {
      it: "Data elaborazione",
      en: "Processing date ",
      de: "Datum dem erstellung",
    },
    clients: {
      it: "Cliente",
      en: "Client ",
      de: "Kunde ",
    },
    address: {
      it: "Indirizzo ",
      en: "Address",
      de: "Adresse",
    },
    codcli: {
      it: "Codice cliente ",
      en: "Client code",
      de: "Client-Code",
    },
    phone: {
      it: "Tel ",
      en: "Phone",
      de: "Telefonnummer",
    },
    fax: {
      it: "Fax",
      en: "Fax",
      de: "Fax",
    },
    datafattura: {
      it: "Data fattura",
      en: "Invoice date",
      de: "Rechnungsdatum ",
    },
    datascadenza: {
      it: "Data scadenza",
      en: "Expiration date ",
      de: "Haltbarkeitsdatum  ",
    },
    importototalescadenza: {
      it: "Importo",
      en: "Total ",
      de: "Menge",
    },
    numrif: {
      it: "# doc",
      en: "# doc ",
      de: "# doc",
    },
    tipodoc: {
      it: "Tipo doc",
      en: "Type doc ",
      de: "Dokumenttyp. ",
    },
    totscad: {
      it: "Totale scadenze",
      en: "Total deadlines  ",
      de: "Gesamtfristen  ",
    },
  },
  cookieBar:{
    btnaccept:{
      it: "Accetta tutto",
      en: "Accept all cookie",
      de: "Alle cookies",
    },
    btnnecessari: {
      it: "Rifiuta tutti",
      en: "Rejects all",
      de: "Alle ablehnen",
    },
    linknotelegali: {
      it: "Note legali",
      en: "Privacy Policy",
      de: "Datenschutz-Bestimmungen",
    },
    btnpreferenze: {
      it: "Preferenze cookie",
      en: "cookie preferences",
      de: "Cookie-Einstellungen",
    },
    cookienecessari: {
      it: (
        <div>
          Cookie necessari
          <br />
          (Obbligatori)
        </div>
      ),
      en: (
        <div>
          Essential cookies
          <br />
          (Required)
        </div>
      ),
      de: (
        <div>
          Notwendige Kekse
          <br />
          (Erforderlich)
        </div>
      ),
    },
    cookieanalitici: {
      it: "Cookie analitici",
      en: "Analytical cookies",
      de: "Analytische Cookies",
    },
    cookieprofilazione: {
      it: "Cookie profilazione",
      en: "Profiling cookies",
      de: "Profiling-Cookies",
    },
    btnconferma: {
      it: "Conferma le mie scelte",
      en: "Confirm",
      de: "Bestätigung",
    },
    textlinkinfo: {
      it: "Scopri di più su",
      en: "Find out more",
      de: "Erfahren Sie mehr über",
    }
    

  }

};

export default Globals;
