import React, { Component } from "react";
import Globals from "../globals/globals";
import Loading from "./../components/Loading.js";
import { Redirect } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import Select from "react-select";
import { toast } from "react-toastify";

import ReactTable from "react-table";
import "react-datepicker/dist/react-datepicker.css";

import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import "react-table/react-table.css";

import it from "date-fns/locale/it";
import en from "date-fns/locale/en-GB";
import de from "date-fns/locale/de";

registerLocale("it", it);
registerLocale("en", en);
registerLocale("de", de);

let controller = new AbortController();

export default class ConfigAutomaticEmail extends Component {
   constructor(props) {
      super(props);

      this.state = {
         language: props.language,
         accessToken: props.accessToken,
         clientSelected: {
            value: "",
            label: Globals.labels.allClients[props.language],
         },
         userData: null,
         isAdmin: null,

         automaticemailsData: null,
         automaticemailsMessage: "",

         //selectOptions: [],

         Showlog: false,
         ModalLogAction: "",
         ModalLogTitle: "",

         logData: [],
         logMessage: "",
         loadingLog: false,

         loading: true,
      };

      this.handleClientChange = this.handleClientChange.bind(this);
   }

   //Potrebbe essere utile per vedere a chi è già stata inviata --> se già inviata verde
   getTrProps = (state, rowInfo, instance) => {
      if (rowInfo) {
         return {
            style: {
               color: "white",
            },
         };
      }
      return {};
   };

   handleClientChange(element) {
      this.setState({
         clientSelected: element,
      });
   }

   formatDate(date) {
      var d = new Date(date),
         month = "" + (d.getMonth() + 1),
         day = "" + d.getDate(),
         year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      if (year === 1970) {
         return null;
      }

      return [year, month, day].join("-");
   }

   componentWillUnmount() {
      document.body.classList.remove("accountstatement-page");
   }

   componentDidMount() {
      this.checkUserAdmin();

      window.scrollTo(0, 1000);
      document.body.classList.add("accountstatement-page");

      document.title = Globals.titles.accountstatement[this.state.language];

      this.getAllEmailContact();
   }

   checkUserAdmin() {
      let accessToken = this.state.accessToken;

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=getUserData&access_token=" +
         accessToken;

      fetch(url, {
         method: "GET",
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               this.setState({
                  userData: {},
               });
            } else {
               // Stop loading state
               this.setState({
                  userData: responseJson.userData,
                  loading: true,
               });

               if (this.state.userData.type === "admin") {
                  this.setState({
                     isAdmin: true,
                  });
               }

               if (this.state.userData.type !== "admin") {
                  this.setState({
                     isAdmin: false,
                  });
               }
            }
         })
         .catch((error) => {
            this.setState({
               userData: {},
            });
         });
   }

   componentWillReceiveProps(props) {
      this.setState({
         language: props.language,
         accessToken: props.accessToken,
      });
      document.title = Globals.titles.automaticemail[props.language];
   }

   //ottengo TUTTE le mail dei clienti
   //TODO bisognerà collegare le mail clienti non a Gallieno dal sito di eliovero ma bensi dal da EMBYON, quando ci daraano i dati
   getAllEmailContact() {
      let formdata = new FormData();

      this.setState({
         automaticemailsData: null,
         automaticemailsMessage: "",
      });

      let accessToken = this.state.accessToken;

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=getClientMailAutomatic&access_token=" +
         accessToken;

      formdata.append("clientCode", this.state.clientSelected.value);

      fetch(url, {
         method: "POST",
         body: formdata,
         signal: controller.signal,
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               // Error during the call - handle message
               this.setState({
                  automaticemailsMessage: responseJson.message,
                  automaticemailsData: [],
               });
            } else {
               // Call is ok, store AccountstaementData in state
               this.setState({
                  automaticemailsMessage: "",
                  automaticemailsData: responseJson.clientlist,
               });
            }
         })
         .catch((error) => {
            // Communication error - handle message
            if (error.name === "AbortError") {
               // handle abort()
               this.setState({
                  automaticemailsData: [],
                  automaticemailsMessage:
                     Globals.reactTableText.stopSignal[this.state.language],
               });
            } else {
               this.setState({
                  automaticemailsData: [],
                  automaticemailsMessage:
                     Globals.errors.serverCall[this.state.language],
               });
            }
         });
   }

   stopLoading() {
      controller.abort();
      controller = new AbortController();
   }

   flagAllYes() {
      let accessToken = this.state.accessToken;
      let formdata = new FormData();

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=UploadAllEmailClientRow&access_token=" +
         accessToken;

      fetch(url, {
         method: "POST",
         body: formdata,
         signal: controller.signal,
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               // Error during the call - handle message
               toast(responseJson.message, {
                  autoClose: 8000,
                  className: "std-black-toast",
                  bodyClassName: "std-black-toast-body",
                  progressClassName: "std-black-toast-progress-bar",
               });

               this.setState({
                  loading: false,
               });
            } else {
               // Call is ok, store AccountstaementData in state
               toast("Operazione completata", {
                  autoClose: 4000,
                  className: "std-black-toast",
                  bodyClassName: "std-black-toast-body",
                  progressClassName: "std-black-toast-progress-bar",
               });
               this.getAllEmailContact();

               this.setState({
                  loading: false,
               });
            }
         })
         .catch((error) => {
            toast(Globals.errors.serverCall[this.state.language], {
               autoClose: 8000,
               className: "std-black-toast",
               bodyClassName: "std-black-toast-body",
               progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
               loading: false,
            });
         });
   }

   deselectAll() {
      let accessToken = this.state.accessToken;
      let formdata = new FormData();

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=UploadAllEmailClientToFalse&access_token=" +
         accessToken;

      fetch(url, {
         method: "POST",
         body: formdata,
         signal: controller.signal,
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               // Error during the call - handle message
               toast(responseJson.message, {
                  autoClose: 8000,
                  className: "std-black-toast",
                  bodyClassName: "std-black-toast-body",
                  progressClassName: "std-black-toast-progress-bar",
               });

               this.setState({
                  loading: false,
               });
            } else {
               // Call is ok, store AccountstaementData in state
               toast("Operazione completata", {
                  autoClose: 4000,
                  className: "std-black-toast",
                  bodyClassName: "std-black-toast-body",
                  progressClassName: "std-black-toast-progress-bar",
               });
               this.getAllEmailContact();

               this.setState({
                  loading: false,
               });
            }
         })
         .catch((error) => {
            toast(Globals.errors.serverCall[this.state.language], {
               autoClose: 8000,
               className: "std-black-toast",
               bodyClassName: "std-black-toast-body",
               progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
               loading: false,
            });
         });
   }

   getLogClient(codcli) {
      this.toggleFormLogModal("Storico email inviate", "getLogClient");

      let formdata = new FormData();

      this.setState({
         loadingLog: true,
      });

      let clientCode = codcli;
      let accessToken = this.state.accessToken;

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=getLogClient&access_token=" +
         accessToken;

      formdata.append("clientCode", clientCode);

      fetch(url, {
         method: "POST",
         body: formdata,
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               // Error during the call - handle message
               this.setState({
                  logMessage: responseJson.message,
                  logData: [],
                  loadingLog: false,
               });
            } else {
               // Call is ok, store AccountstaementData in state
               this.setState({
                  logMessage: "",
                  logData: responseJson.log,
                  loadingLog: false,
                  titleCodCli: codcli,
               });
            }
         })
         .catch((error) => {
            // Communication error - handle message
            this.setState({
               logData: [],
               logMessage: Globals.errors.serverCall[this.state.language],
               loadingLog: false,
            });
         });
   }

   toggleFormLogModal(title, action) {
      let Showlog = this.state.Showlog;

      if (Showlog === true) {
         Showlog = false;
      } else {
         Showlog = true;
      }

      this.setState({
         Showlog: Showlog,
         ModalLogTitle: title,
         ModalLogAction: action,
      });
   }

   // Serve per attivare il singolo cliente alle mail automatiche
   activeThis(client) {
      let accessToken = this.state.accessToken;
      let formdata = new FormData();

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=UploadthisEmailClient&access_token=" +
         accessToken;

      formdata.append("clientCode", client);

      fetch(url, {
         method: "POST",
         body: formdata,
         signal: controller.signal,
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               // Error during the call - handle message
               toast(responseJson.message, {
                  autoClose: 8000,
                  className: "std-black-toast",
                  bodyClassName: "std-black-toast-body",
                  progressClassName: "std-black-toast-progress-bar",
               });

               this.setState({
                  loading: false,
               });
            } else {
               // Call is ok, store AccountstaementData in state
               toast("Operazione completata", {
                  autoClose: 4000,
                  className: "std-black-toast",
                  bodyClassName: "std-black-toast-body",
                  progressClassName: "std-black-toast-progress-bar",
               });
               this.getAllEmailContact();

               this.setState({
                  loading: false,
               });
            }
         })
         .catch((error) => {
            toast(Globals.errors.serverCall[this.state.language], {
               autoClose: 8000,
               className: "std-black-toast",
               bodyClassName: "std-black-toast-body",
               progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
               loading: false,
            });
         });
   }

   render() {
      if (this.state.userData === null) {
         return <Loading />;
      }

      if (this.state.userData.type !== "admin") {
         return <Redirect to={{ pathname: "/" }} />;
      }

      let clientsFilter = "";
      if (
         this.state.userData.type === "admin" &&
         this.state.userData.clients !== undefined
      ) {
         //let selectOptions = [];
         let selectOptions = [
            {
               value: "",
               label: Globals.labels.allClients[this.state.language],
            },
         ];

         for (let c = 0; c < this.state.userData.clientsWithEmail.length; c++) {
            selectOptions.push({
               value: this.state.userData.clientsWithEmail[c].codconto,
               label: this.state.userData.clientsWithEmail[c].label,
            });
         }

         let selectedOption = this.state.clientSelected;

         let clientsSelect = (
            <Select
               value={selectedOption}
               onChange={this.handleClientChange}
               options={selectOptions}
               isSearchable={true}
               isClearable={true}
            />
         );

         clientsFilter = (
            <div className="filter-wrapper-multiselect">
               <div className="label">
                  {Globals.labels.client[this.state.language]}
               </div>
               <div className="filter multiselect-wrapper">{clientsSelect}</div>
            </div>
         );
      }

      let automaticemailsMessage = this.state.automaticemailsMessage;

      let automaticemailsData = this.state.automaticemailsData;
      let automaticemailsRender = "";
      let ModalLog = "";

      if (automaticemailsData === null) {
         automaticemailsRender = (
            <div className="loading-wrapper">
               <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            </div>
         );
      } else {
         if (automaticemailsMessage !== "") {
            automaticemailsRender = (
               <div className="message-wrapper">{automaticemailsMessage}</div>
            );
         } else {
            const columns = [
               {
                  Header: Globals.labels.codcli[this.state.language],
                  accessor: "codconto",
               },
               {
                  Header: Globals.labels.company[this.state.language],
                  accessor: "disconto",
                  filterable: true,
                  filterMethod: (filter, row) => {
                     return row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase());
                  },
               },
               {
                  Header: Globals.labels.email[this.state.language],
                  accessor: "email",
                  filterable: true,
                  filterMethod: (filter, row) => {
                     return row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase());
                  },
               },
               {
                  Header: Globals.labels.emailauto[this.state.language],
                  accessor: "email_automatiche",
               },
               {
                  Header: Globals.labels.Dataultimoinvio[this.state.language],
                  accessor: "ultimoinvio",
               },
               {
                  Header: Globals.labels.log[this.state.language],
                  accessor: "log",
               },
            ];

            const data = [];
            for (let d = 0; d < automaticemailsData.length; d++) {
               data.push({
                  codconto: automaticemailsData[d].codconto,
                  disconto: automaticemailsData[d].dscconto1,
                  email: automaticemailsData[d].emailruolo,
                  email_automatiche:
                     automaticemailsData[d].email_automatiche === "0" ? (
                        <i
                           className="std-hoverable-cursor-pointer fa fa-times  fa-2x"
                           style={{ color: "red" }}
                           onClick={() => {
                              this.activeThis(automaticemailsData[d].codconto);
                           }}
                        ></i>
                     ) : (
                        <i
                           className="std-hoverable-cursor-pointer fa fa-envelope-o  fa-2x"
                           style={{ color: "green" }}
                           onClick={() => {
                              this.activeThis(automaticemailsData[d].codconto);
                           }}
                        ></i>
                     ),
                  ultimoinvio: automaticemailsData[d].ultimoinvio,
                  log: (
                     <i
                        className="std-hoverable-cursor-pointer fa fa-folder-open-o  fa-2x"
                        onClick={() => {
                           this.getLogClient(automaticemailsData[d].codconto);
                        }}
                     ></i>
                  ),
               });
            }

            // Render Table
            automaticemailsRender = (
               <ReactTable
                  data={data}
                  columns={columns}
                  pageSizeOptions={[5, 15, 25, 50, 100]}
                  defaultPageSize={25}
                  page={this.state.page}
                  onPageChange={(page) => this.setState({ page })}
                  previousText={
                     Globals.reactTableText.previous[this.state.language]
                  }
                  nextText={Globals.reactTableText.next[this.state.language]}
                  loadingText={
                     Globals.reactTableText.loading[this.state.language]
                  }
                  noDataText={
                     Globals.reactTableText.noData[this.state.language]
                  }
                  pageText={Globals.reactTableText.page[this.state.language]}
                  ofText={Globals.reactTableText.of[this.state.language]}
                  rowsText={Globals.reactTableText.rows[this.state.language]}
                  pageJumpText={
                     Globals.reactTableText.pageJumpText[this.state.language]
                  }
                  rowsSelectorText={
                     Globals.reactTableText.rowsSelectorText[
                        this.state.language
                     ]
                  }
                  getTrProps={this.getTrProps}
               />
            );

            if (this.state.Showlog === true) {
               let logData = this.state.logData;
               let logMessage = this.state.logMessage;

               let Log = "";

               const datalog = [];
               for (let d = 0; d < logData.length; d++) {
                  datalog.push({
                     timestamp: logData[d].send_timestamp,
                     success:
                        logData[d].success === "0" ? (
                           <i
                              className="std-hoverable-cursor-pointer fa fa-times  fa-2x"
                              style={{ color: "red" }}
                           ></i>
                        ) : (
                           <i
                              className="std-hoverable-cursor-pointer fa fa-check fa-2x"
                              style={{ color: "green" }}
                           ></i>
                        ),
                  });
               }

               const columnLog = [
                  {
                     Header: Globals.labels.Datainvio[this.state.language],
                     accessor: "timestamp",
                     filterable: true,
                     filterMethod: (filter, row) => {
                        return row[filter.id]
                           .toLowerCase()
                           .includes(filter.value.toLowerCase());
                     },
                  },
                  {
                     Header: Globals.labels.successo[this.state.language],
                     accessor: "success",
                  },
               ];

               Log = (
                  <ReactTable
                     data={datalog}
                     columns={columnLog}
                     pageSizeOptions={[5, 15, 25]}
                     defaultPageSize={15}
                     previousText={
                        Globals.reactTableText.previous[this.state.language]
                     }
                     nextText={Globals.reactTableText.next[this.state.language]}
                     loadingText={
                        Globals.reactTableText.loading[this.state.language]
                     }
                     noDataText={
                        Globals.reactTableText.noData[this.state.language]
                     }
                     pageText={Globals.reactTableText.page[this.state.language]}
                     ofText={Globals.reactTableText.of[this.state.language]}
                     rowsText={Globals.reactTableText.rows[this.state.language]}
                     pageJumpText={
                        Globals.reactTableText.pageJumpText[this.state.language]
                     }
                     rowsSelectorText={
                        Globals.reactTableText.rowsSelectorText[
                           this.state.language
                        ]
                     }
                  />
               );

               //modal dei log

               ModalLog = (
                  <div className="modal">
                     <div className="background"></div>
                     <div className="body">
                        <div className="titleBar">
                           {this.state.ModalLogTitle} - {this.state.titleCodCli}
                           <div className="close-button">
                              <i
                                 className="fa fa-times"
                                 onClick={() => this.toggleFormLogModal("", "")}
                              ></i>
                           </div>
                        </div>
                        {this.state.loadingLog === true ? (
                           <div className="loading-wrapper">
                              <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                           </div>
                        ) : logData.length === 0 ? (
                           <div className="message-wrapper"> {logMessage} </div>
                        ) : (
                           Log
                        )}
                     </div>
                  </div>
               );
            }
         }
      }

      return (
         <div className="accountstatement-page">
            <div className="filters">
               <div className="filters-wrapper">
                  {clientsFilter}

                  <div className="filter-wrapper">
                     <div className="filter">
                        <div className="label">
                           <button
                              type="button"
                              onClick={() => this.deselectAll()}
                           >
                              DESELEZIONA TUTTI
                           </button>
                        </div>
                     </div>
                  </div>

                  <div className="filter-wrapper">
                     <div className="filter">
                        <div className="label">
                           <button
                              type="button"
                              onClick={() => this.flagAllYes()}
                           >
                              ATTIVA TUTTI
                           </button>
                        </div>
                     </div>
                  </div>

                  <div className="filter-wrapper">
                     <div className="filter">
                        <div className="label">
                           <button
                              type="button"
                              onClick={() => this.getAllEmailContact()}
                           >
                              {Globals.labels.applyFilter[this.state.language]}
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {automaticemailsRender}
            {ModalLog}
         </div>
      );
   }
}
