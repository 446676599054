import React, { Component } from "react";
import Globals from "../globals/globals";
import Loading from "./../components/Loading.js";
import { Redirect } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import ReactTable from "react-table";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import { Progress } from "react-sweet-progress";

import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import "react-table/react-table.css";
import "react-sweet-progress/lib/style.css";

import it from "date-fns/locale/it";
import en from "date-fns/locale/en-GB";
import de from "date-fns/locale/de";
import moment from "moment";

registerLocale("it", it);
registerLocale("en", en);
registerLocale("de", de);

let controller = new AbortController();

export default class Revenue extends Component {
   constructor(props) {
      super(props);

      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 5);

      this.state = {
         language: props.language,
         accessToken: props.accessToken,
         clientSelected: {
            value: null,
            label: Globals.labels.selectClients[props.language],
         },
         startDate: startDate,
         endDate: new Date(),
         userData: null,

         revenueData: null,
         revenueMessage: "",
         opendeadlinesData: null,
         opendeadlinesMessage: "",
         totaldeadlinesData: null,

         fidoPercentage: "",
         fidoeuro: "",
         sommascadenze: "",
         colorbar: "success",
         lastcheck: null,

         selectedOption: [],
         fatturatoTot: 0,
         percentuale: 0,

         loading: true,
         isMounted: false,
      };
      this.handleClientChange = this.handleClientChange.bind(this);
      this.handleStartDateChange = this.handleStartDateChange.bind(this);
      this.handleEndDateChange = this.handleEndDateChange.bind(this);
   }

   handleClientChange(element) {
      this.setState({
         clientSelected: element,
      });
   }

   handleStartDateChange(date) {
      this.setState({
         startDate: date,
      });
   }

   handleEndDateChange(date) {
      this.setState({
         endDate: date,
      });
   }

   handleRevenueTotal(total) {
      this.setState({
         fatturatoTot: total,
      });
   }

   formatDate(date) {
      var d = new Date(date),
         month = "" + (d.getMonth() + 1),
         year = d.getFullYear();
      let day = "01";

      if (month.length < 2) month = "0" + month;

      return [year, month, day].join("-");
   }

   formatExportDate(date) {
      var d = new Date(date),
         month = "" + (d.getMonth() + 1),
         year = d.getFullYear();

      if (month.length < 2) month = "0" + month;

      return [year, month].join("-");
   }

   componentWillUnmount() {
      document.body.classList.remove("accountstatement-page");
   }

   componentDidMount() {
      document.body.classList.add("accountstatement-page");

      document.title = Globals.titles.revenue[this.state.language];

      // Fetch api for the user data
      let accessToken = this.state.accessToken;

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=getUserData&access_token=" +
         accessToken;

      fetch(url, {
         method: "GET",
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               this.setState({
                  userData: {},
               });
            } else {
               // Stop loading state
               this.setState({
                  userData: responseJson.userData,
               });

               if (
                  this.state.userData.type === "admin" ||
                  this.state.userData.type === "partner"
               ) {
                  this.setState({
                     isMounted: true,
                  });
               }

               if (this.state.userData.type === "user") {
                  this.getStatement();
               }
            }
         })
         .catch((error) => {
            this.setState({
               userData: {},
            });
         });
   }

   componentWillReceiveProps(props) {
      this.setState({
         language: props.language,
         accessToken: props.accessToken,
      });
      document.title = Globals.titles.revenue[props.language];
   }

   getStatement() {
      let formdata = new FormData();

      if (
         this.state.userData.type === "partner" &&
         this.state.clientSelected.value === null
      ) {
         this.setState({
            revenueMessage: Globals.messages.selectFilter[this.state.language],
         });
      }

      if (
         this.state.userData.type === "admin" ||
         this.state.userData.type === "partner"
      ) {
         this.setState({
            isMounted: false,
         });
      }

      if (
         this.formatDate(this.state.endDate) <
         this.formatDate(this.state.startDate)
      ) {
         this.setState({
            revenueData: [],
            revenueMessage:
               Globals.errors.nonSequentialDates[this.state.language],
         });
         return;
      }

      this.setState({
         revenueData: null,
         revenueMessage: "",
         fidoPercentage: "",
      });

      let accessToken = this.state.accessToken;

      let url =
         Globals.baseUrl +
         "?lang=" +
         this.state.language +
         "&a=getRevenue&access_token=" +
         accessToken;

      formdata.append("startDate", this.formatDate(this.state.startDate));
      formdata.append("endDate", this.formatDate(this.state.endDate));

      //let listclient = this.state.selectedOption;

      if (
         this.state.userData.type === "admin" ||
         this.state.userData.type === "partner"
      ) {
         //formdata.append( "clientCode", JSON.stringify(listclient) );
         formdata.append("clientCode", this.state.clientSelected.value);
      }

      fetch(url, {
         method: "POST",
         body: formdata,
         signal: controller.signal,
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.error === true) {
               // Error during the call - handle message
               this.setState({
                  revenueData: [],
                  revenueMessage: responseJson.message,

                  opendeadlinesData: [],
                  totaldeadlinesData: [],
               });
            } else {
               // Call is ok, store Revenue in state
               this.setState({
                  revenueData: responseJson.revenue,
                  fidoPercentage: responseJson.fidopercentage,
                  fidoeuro: responseJson.fidoeuro,
                  sommascadenze: responseJson.sommascadenze,
                  lastcheck: responseJson.last_check,
                  revenueMessage: "",
               });

               if (this.state.fidoPercentage >= 80) {
                  this.setState({
                     colorbar: "error",
                  });
               } else {
                  this.setState({
                     colorbar: "success",
                  });
               }
            }
         })
         .catch((error) => {
            // Communication error - handle message
            if (error.name === "AbortError") {
               // handle abort()
               this.setState({
                  revenueData: [],
                  revenueMessage:
                     Globals.reactTableText.stopSignal[this.state.language],
               });
            } else {
               this.setState({
                  revenueData: [],
                  revenueMessage:
                     Globals.errors.serverCall[this.state.language],
               });
            }
         });
   }

   stopLoading() {
      controller.abort();
      controller = new AbortController();
   }

   render() {
      if (this.state.userData === null) {
         return <Loading />;
      }

      if (this.state.userData.name === undefined) {
         return <Redirect to={{ pathname: "/" }} />;
      }

      let clientsFilter = "";
      if (
         (this.state.userData.type === "admin" &&
            this.state.userData.clients !== undefined) ||
         this.state.userData.type === "partner"
      ) {
         // let selectOptions = [];
         let selectOptions = [
            {
               value: null,
               label: Globals.labels.selectClients[this.state.language],
            },
         ];

         for (let c = 0; c < this.state.userData.clients.length; c++) {
            selectOptions.push({
               value: this.state.userData.clients[c].codconto,
               label: this.state.userData.clients[c].label,
            });
         }

         let selectedOption = this.state.clientSelected;

         let clientsSelect = (
            <Select
               value={selectedOption}
               onChange={this.handleClientChange}
               options={selectOptions}
               isSearchable={true}
               isClearable={true}
            />
         );

         clientsFilter = (
            <div className="filter-wrapper-multiselect">
               <div className="label">
                  {Globals.labels.client[this.state.language]}
               </div>
               <div className="filter multiselect-wrapper">{clientsSelect}</div>
            </div>
         );
      }

      let revenueData = this.state.revenueData;
      let revenueMessage = this.state.revenueMessage;
      let lastcheckRender = "";
      let revenueRender = "";
      let fatturatoRender = "";

      if (this.state.isMounted === true) {
         revenueRender = (
            <div className="message-wrapper">
               {Globals.messages.selectFilter[this.state.language]}
            </div>
         );
      } else {
         if (revenueData === null) {
            revenueRender = (
               <div className="loading-wrapper">
                  <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                  <div className="loading-wrapper">
                     {" "}
                     <button
                        type="button"
                        className="stopbutton"
                        onClick={() => this.stopLoading()}
                     >
                        {Globals.labels.stop[this.state.language]}
                     </button>{" "}
                  </div>
               </div>
            );
         } else {
            if (revenueMessage !== "") {
               revenueRender = (
                  <div className="message-wrapper">{revenueMessage}</div>
               );
            } else {
               const columns = [
                  {
                     Header: Globals.labels.codcli[this.state.language],
                     accessor: "codcli",
                     show:
                        this.state.userData.type === "admin" ||
                        this.state.userData.type === "partner"
                           ? true
                           : false,
                  },
                  {
                     Header: Globals.labels.company[this.state.language],
                     accessor: "disconto",
                     show:
                        this.state.userData.type === "admin" ||
                        this.state.userData.type === "partner"
                           ? true
                           : false,
                  },
                  {
                     Header: Globals.labels.esercizio[this.state.language],
                     accessor: "esercizio",
                     filterable: true,
                     filterMethod: (filter, row) => {
                        return row[filter.id]
                           .toLowerCase()
                           .includes(filter.value.toLowerCase());
                     },
                  },
                  {
                     Header: Globals.labels.mese[this.state.language],
                     accessor: "mese",
                     filterable: true,
                     filterMethod: (filter, row) => {
                        return row[filter.id]
                           .toLowerCase()
                           .includes(filter.value.toLowerCase());
                     },
                  },
                  {
                     Header: Globals.labels.fatturatoeuro[this.state.language],
                     accessor: "fatturatoeuro",
                     Cell: (c) => (
                        <CurrencyFormat
                           value={c.value}
                           displayType={"text"}
                           thousandSeparator={","}
                           decimalScale={2}
                           prefix={" € "}
                           renderText={(value) => <div>{value}</div>}
                        />
                     ),
                  },
                  {
                     Header:
                        Globals.labels.fatturatoeuroef[this.state.language],
                     accessor: "fatturatoeuroef",
                     Cell: (c) => (
                        <CurrencyFormat
                           value={c.value}
                           displayType={"text"}
                           thousandSeparator={","}
                           decimalScale={2}
                           prefix={" € "}
                           renderText={(value) => <div>{value}</div>}
                        />
                     ),
                  },
                  {
                     Header:
                        Globals.labels.fatturatoeurotf[this.state.language],
                     accessor: "fatturatoeurotf",
                     Cell: (c) => (
                        <CurrencyFormat
                           value={c.value}
                           displayType={"text"}
                           thousandSeparator={","}
                           decimalScale={2}
                           prefix={" € "}
                           renderText={(value) => <div>{value}</div>}
                        />
                     ),
                  },
               ];
               const data = [];
               let fatturatoTemp = 0;
               for (let c = 0; c < revenueData.length; c++) {
                  data.push({
                     codcli: revenueData[c].codcli,
                     disconto: revenueData[c].dscconto1,
                     esercizio: revenueData[c].esercizio,
                     mese: revenueData[c].mese,
                     fatturatoeuro: revenueData[c].fatturatoeuro,
                     fatturatoeuroef: revenueData[c].fatturatoeuroef,
                     fatturatoeurotf: revenueData[c].fatturatoeurotf,
                  });

                  if (c !== 0) {
                     fatturatoTemp =
                        fatturatoTemp +
                        parseFloat(revenueData[c].fatturatoeuro);
                  } else {
                     fatturatoTemp = parseFloat(revenueData[c].fatturatoeuro);
                  }
               }

               fatturatoRender = (
                  <div className="total-label">
                     <p className="total-date">
                        {Globals.labels.totale[this.state.language]}
                     </p>
                     <CurrencyFormat
                        value={fatturatoTemp}
                        displayType={"text"}
                        thousandSeparator={","}
                        decimalScale={2}
                        prefix={" € "}
                        renderText={(value) => <div>{value}</div>}
                     />
                     <p className="total-date">
                        {" "}
                        {Globals.labels.da[this.state.language]}{" "}
                        {this.formatExportDate(this.state.startDate)}{" "}
                        {Globals.labels.a[this.state.language]}{" "}
                        {this.formatExportDate(this.state.endDate)}{" "}
                     </p>{" "}
                  </div>
               );

               if (this.state.lastcheck !== null  ) {

                  let t = this.state.lastcheck;
                  lastcheckRender = <p> {Globals.messages.lastdateModified[this.state.language]} :  {moment(t[0].last_time).format("DD/MM/YYYY, HH:mm")} </p>;
               }

               // Render Table
               revenueRender = (
                  <ReactTable
                     data={data}
                     columns={columns}
                     pageSizeOptions={[5, 15, 25, 50, 100]}
                     defaultPageSize={25}
                     page={this.state.page}
                     onPageChange={(page) => this.setState({ page })}
                     previousText={
                        Globals.reactTableText.previous[this.state.language]
                     }
                     nextText={Globals.reactTableText.next[this.state.language]}
                     loadingText={
                        Globals.reactTableText.loading[this.state.language]
                     }
                     noDataText={
                        Globals.reactTableText.noData[this.state.language]
                     }
                     pageText={Globals.reactTableText.page[this.state.language]}
                     ofText={Globals.reactTableText.of[this.state.language]}
                     rowsText={Globals.reactTableText.rows[this.state.language]}
                     pageJumpText={
                        Globals.reactTableText.pageJumpText[this.state.language]
                     }
                     rowsSelectorText={
                        Globals.reactTableText.rowsSelectorText[
                           this.state.language
                        ]
                     }
                  />
               );
            }
         }
      }

      return (
         <div className="accountstatement-page">
            <div className="filters">
               {lastcheckRender}
               <div className="filters-wrapper">
                  {clientsFilter}
                  <div className="filter-wrapper">
                     <div className="label">
                        {Globals.labels.startDate[this.state.language]}
                     </div>
                     <div className="filter">
                        <DatePicker
                           locale={this.state.language}
                           selected={this.state.startDate}
                           dateFormat="MMMM yyyy"
                           showMonthYearPicker
                           onChange={this.handleStartDateChange}
                        />
                     </div>
                  </div>
                  <div className="filter-wrapper">
                     <div className="label">
                        {Globals.labels.endDate[this.state.language]}
                     </div>
                     <div className="filter">
                        <DatePicker
                           locale={this.state.language}
                           selected={this.state.endDate}
                           dateFormat="MMMM yyyy"
                           showMonthYearPicker
                           onChange={this.handleEndDateChange}
                        />
                     </div>
                  </div>
                  <div className="filter-wrapper">
                     <div className="label">&nbsp;</div>
                     <div className="filter">
                        <button
                           type="button"
                           onClick={() => this.getStatement()}
                        >
                           {Globals.labels.apply[this.state.language]}
                        </button>
                     </div>
                  </div>
                  <div className="filter-wrapper">
                     <div className="label">&nbsp;</div>
                     <div className="filter">
                        <div className="total-label">{fatturatoRender}</div>
                     </div>
                  </div>
                  <div className="filter-wrapper progressbar">
                     <div className="label">&nbsp;</div>
                     <div className="filter">
                        {this.state.fidoPercentage === "" ? (
                           <span> </span>
                        ) : (
                           <div>
                              <div>
                                 <span style={{ textAlign: "left" }}>
                                    {parseInt(this.state.fidoPercentage)} {}{" "}
                                    {
                                       Globals.labels.percentualefido[
                                          this.state.language
                                       ]
                                    }
                                 </span>

                                 <span style={{ float: "right" }}>
                                    {
                                       Globals.labels.fatturatoattivo[
                                          this.state.language
                                       ]
                                    }{" "}
                                    :{" "}
                                    {
                                       <CurrencyFormat
                                          value={this.state.sommascadenze}
                                          displayType={"text"}
                                          thousandSeparator={","}
                                          decimalScale={2}
                                          prefix={" € "}
                                          renderText={(value) => (
                                             <span>{value}</span>
                                          )}
                                       />
                                    }{" "}
                                    {}
                                    {
                                       Globals.labels.fidoeuro[
                                          this.state.language
                                       ]
                                    }{" "}
                                    :{" "}
                                    {
                                       <CurrencyFormat
                                          value={this.state.fidoeuro}
                                          displayType={"text"}
                                          thousandSeparator={","}
                                          decimalScale={2}
                                          prefix={" € "}
                                          renderText={(value) => (
                                             <span>{value}</span>
                                          )}
                                       />
                                    }
                                 </span>
                              </div>

                              {this.state.fidoPercentage > 100 ? (
                                 <Progress
                                    percent={100}
                                    status={this.state.colorbar}
                                 />
                              ) : (
                                 <Progress
                                    percent={this.state.fidoPercentage}
                                    status={this.state.colorbar}
                                 />
                              )}

                           </div>
                        )}
                     </div>
                  </div>
               </div>
            </div>
            <br clear="all" />
            <br clear="all" />

            {revenueRender}
         </div>
      );
   }
}
